import React, { useRef, useState,useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";
import { API_URL } from '../config/config';
import "./DutyFormShahbad.css";
import html2canvas from 'html2canvas';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import data from './data.json';


const Data=data;

function filterByPS(data, psValue) {
  return data.filter(entry => entry.PS === psValue);
}

const DutyForm = () =>  {
  
  const componentRef = useRef(); 
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-GB");
  const formatDate = (date) => date.toISOString().split('T')[0];
  const dayNumber = currentDate.getDay();

// Convert the number to the name of the day
const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const currentDay = daysOfWeek[dayNumber];

const ShahabadData =filterByPS(Data , 'SHAHBAD DAIRY')
// Filter beats for current day with Count > 8
const beatsForCurrentDay= ShahabadData
    .filter(entry => entry.DayOfWeek === currentDay && parseInt(entry.Count) > 3)
    .map(entry => entry.BEAT);

    console.log(beatsForCurrentDay)

  const [entries, setEntries] = useState({
    SHO: ["INSP. ASHOK KUMAR"],
    LAW_AND_ORDER: ["INSP. SANJAY KUMAR"],
    INSPECTOR_INVESTIGATION: [""],
    DUTY_OFFICER: ["HC DEVENDER 241/OND", "ASI INAMDAR 104/OND", "WHC SARITA 1557/OND"],
    DD_WRITER: ["WCT. SUSHIL 1675/OND", "WCT. KAVITA 1694/OND"],
    SENTRIES: [
      "CT. MOHIT 2322/OND (MAIN GATE)",
      "HC RAMKISHAN 2116/OND (LOCK UP)",
      "HC RAJBIR 387/OND"
    ],
    READER_DAK_DUTY: ["SI PRADEEP 198-D", "W/CT JYOTI 1519/OND", "SI MADAN 635/OND"],
    DEPLOYMENT_OFFICER: ["ASI NARENDER 1057/OND", "WHC BABY 1537/OND", "CT. RAVINDER 2040/OND"],
    RECORD: ["HC JITENDER 411/OND", "W/CT SUSHIL 1675", "CT SHAMSHER 2309/OND"],
    MALKHANA_GP: ["HC ASHOK 2613/OND", "HC RAMKISHAN 2116/OND"],
    MALKHANA_CP: ["HC MANJEET 2135/OND", "HC AJAY 2066/OND", "HC DEVENDER 241/OND"]
  });


  const [data, setData] = useState([
    {
      beat: '01',
      location: '(SEC 27, DTU, AMAR JYOTI COLONY)',
      staffNames: [
        'SI RAM SINGH D-3501',
        'HC SATISH 1995/OND',
        'HC HARISH 2182/OND',
        'HC NARENDER 2147/OND',
      ],
    },
    {
      beat: '02',
      location: '(VILL SB DAULATPUR, PRAKASH VIHAR)',
      staffNames: [
        'SI RAM SINGH D-3501',
        'HC NARENDER 2452/OND',
        'HC AJAY 2071/OND',
        'CT GOVIND 2785/OND',
      ],
    },
    {
      beat: '03',
      location: '(SEC 28, SHIV VIHAR)',
      staffNames: [
        'SI RAM SINGH D-3501',
        'HC SATENDER 375/OND',
        'HC ANIRUDH 105/OND',
      ],
    },
    {
      beat: '04',
      location: '(VILL PRAHLAD PUR, SEC 29,30)',
      staffNames: [
        'SI SURENDRA D-6749',
        'HC TARUN 2271/OND',
        'HC DEVENDER 198/OND',
        'HC VIKASH 380/OND',
        'HC DEEPAK 2137/OND',
      ],
    },
    {
      beat: '05',
      location: '(VILL BARWALA, SEC 34,35,36, JAIN COLONY)',
      staffNames: [
        'SI AJAY D-6777',
        'SI MONIL D-1028',
        'HC MUNSHI LAL 2610/OND',
        'HC AJEET 2282/OND',
        'HC AJEET 2298/OND',
        'HC ROOP LAL 2394/OND',
      ],
    },
    {
      beat: '06',
      location: '(PAPPU COLONY, KRISHNA COLONY)',
      staffNames: [
        'SI AJAY D-6777',
        'SI MONIL D-1028',
        'HC RAMESH 2107/OND',
        'HC MD.AIYUB 385/OND',
        'HC ASHISH 442/OND',
      ],
    },
    {
      beat: '07',
      location: '(SHAHBAD DAIRY)',
      staffNames: [
        'SI GHANSHYAM D-6754',
        'SI PINKI D-1802',
        'ASI INDER 469/F',
        'HC PARDEEP 2388/OND',
        'HC UMMED 2439/OND',
        'HC YOGENDER 110/OND',
        'HC NARENDER 989/OND',
        'HC TUSHAR 2159/OND',
        'CT PARDEEP 2494/OND',
        'CT ROHIT 2018/OND',
        'CT SATENDER 2493/OND',
      ],
    },
    {
      beat: '08',
      location: '(SHAHBAD DAIRY, SEC 26 FACE-4)',
      staffNames: [
        'SI GHANSHYAM D-6754',
        'SI AMBIKA D-1809',
        'ASI INDER 469/F',
        'HC VIKRAM 2514/OND',
        'HC PARDEEP 101/OND',
        'HC RAVI 2592/OND',
        'CT MOHINDER 1908/OND',
        'CT GORAV SINGH 2561/OND',
      ],
    },
    {
      beat: '09',
      location: '(PRAHLAD VIHAR, SEC 25, JJ COLONY)',
      staffNames: [
        'SI TEJ SINGH D-5092',
        'HC SATISH 2032/OND',
        'HC KARAN 2225/OND',
        'HC ANUJ 2495/OND',
        'CT DINESH 2024/OND',
      ],
    },
    {
      beat: '10',
      location: '(SEC 11 ROHINI)',
      staffNames: [
        'SI TEJ SINGH D-5092',
        'ASI ABHINAV 141/OND',
        'HC SANJEEV 2433/OND',
        'HC AMIT 2474/OND',
        'HC RAKESH 2285/OND',
        'CT RAVINDER 2485/OND',
        'CT RAJENDER 2451/OND',
      ],
    },
  ]);
  const [Picketdata, setPicketdata]=useState([
  {picket:'1',
    area:'PUNJAB SWEET PASSI MARG',
    staffNames:[''],
  },
  {
    picket:'2',
    area:'RITHALA METRO',
    staffNames:['ASI ABHINAV 141/OND','HC SANJEEV 2433/OND', 'CT RAVINDER 2485/OND'],
  },
  {
    picket:'3',
    area:'PINK BOOTH',
    staffNames:['HC AMIT 2474/OND','CT ROHIT 5198/DHG','ASI RAM NARAYAN 1053/OND'],
  },
  {
    picket:'4',
    area:'ST. XAVIOR',
    staffNames:['HC UMMED 2439/OND','HC PARDEEP 101/OND', 'HC NARENDER 2452/OND','CT SATENDER 2493/OND','CT GORAV SINGH 2561/OND'],
  },
  {picket:'5',
    area:'SECTOR-25',
    staffNames:[''],
  },
  {picket:'6',
    area:'SECTOR-29 KHERA NAHAR	',
    staffNames:[''],
  },
  {
    picket:'7',
    area:'RAJ MANDIR (NEAR TRUE VALUE)',
    staffNames:['HC SATISH 1995/OND','HC MD.AIYUB 385/OND', 'ASI RAM NARAYAN 1053/OND','HC RAKESH 2285/OND','ASI NARENDER 694/OND','ASI RAMESH 647/OND','HC AJAY 2071/OND'],
  },
]);

  const [tasksToSubmit, setTasksToSubmit] = useState([]);

useEffect(() => {
  
}, [tasksToSubmit]); 

const selectedNamePicket = async (name, time, type) => {
  if (name === "None") {
   
    return;
  }

  let startTime, endTime;

  try {
    const response = await axios.post(`${API_URL}/api/users/number`, { name });
    const { name: fetchedName, phoneNumber } = response.data;

    if (time === 'patrolDay') {
      startTime = '08:00:00';
      endTime = '16:00:00';
    } 
    else if(time==='patrolEvening') {
      startTime = '16:00:00';
      endTime = '23:00:00';
    }
    else{
      startTime='23:00:00';
      endTime='23:59:00';
    }

    const task = {
      name: fetchedName,
      phoneNumber: phoneNumber,
      isChecked: true,
      startTime: startTime,
      endTime: endTime,
      type: type,
    };

    setTasksToSubmit(prevTasks => [...prevTasks, task]);

  } catch (error) {
    console.error('Error assigning duty:', error.message);
  }
};
const selectedName = async (name, time, type) => {
  if (name === "None") {
   
    return;
  }

  let startTime, endTime;

  // Define time slots based on the patrol type
  if (time === 'patrolDay') {
    startTime = '09:00:00';
    endTime = '17:00:00';
  } else if (time === 'patrolEvening') {
    startTime = '18:00:00';
    endTime = '23:59:00'; // midnight
  } else if (time === 'patrolNight') {
    startTime = '12:00:00'; // midnight
    endTime = '06:00:00';
  }

  try {
    const response = await axios.post(`${API_URL}/api/users/number`, { name });
    const { name: fetchedName, phoneNumber } = response.data;

    const task = {
      name: fetchedName,
      phoneNumber: phoneNumber,
      isChecked: true,
      startTime: startTime,
      endTime: endTime,
      type: type,
    };

    setTasksToSubmit(prevTasks => [...prevTasks, task]);

  } catch (error) {
    console.error('Error assigning duty:', error.message);
  }
};
const handleTextInputChange = (e, rowIndex, field) => {
  const updatedData = [...data];
  updatedData[rowIndex][field] = e.target.value;
  setData(updatedData);
};

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (tasksToSubmit.length > 0) {
      try {
        // Map through tasks and create an array of promises
        const promises = tasksToSubmit.map((task) => {
          const taskData = {
            name: task.name,
            phoneNumber: task.phoneNumber,
            date: formatDate(currentDate),
            isChecked: task.isChecked,
            startTime: task.startTime,
            endTime: task.endTime,
            type: task.type,
          };
  
          return axios.post(`${API_URL}/api/assignDuty`, taskData);
        });
  
        // Wait for all promises to resolve or reject
        const results = await Promise.allSettled(promises);
  
        // Check the results and handle success or failure for each task
        const allSuccess = results.every(result => result.status === 'fulfilled');
  
        if (allSuccess) {
          toast.success('All duties assigned successfully!', {
            position: 'top-center',
          });
        } else {
          toast.warn('Some duties could not be assigned. Please check the errors.', {
            position: 'top-center',
          });
        }
      } catch (error) {
        console.error('Error assigning duties:', error.response ? error.response.data : error.message);
        toast.error('Failed to assign duties. Please try again later.', {
          position: 'top-center',
        });
      }
    } else {
      toast.warn('Please fill start and end times for necessary tasks.');
    }
  };

  const handleExportPDF = () => {
    const pdf = new jsPDF("p", "mm", "a4");

    pdf.html(componentRef.current, {
      callback: (doc) => {
        doc.save("DutyRoster.pdf");
      },
      x: 10,  // X-axis margin
      y: 10,  // Y-axis margin
      width: 180,  // Width of the content (within the page margins)
      windowWidth: 800,  // Width of the browser window (scaling factor)
    });
  };
  

  const handleExportDOC = () => {
    // Check if componentRef.current exists
    if (componentRef.current) {
      const htmlContent = componentRef.current.outerHTML;

      // Replace input values if necessary
      const updatedHtmlContent = htmlContent.replace(/<input[^>]*value="([^"]*)"/g, (match, value) => {
        return value ? value : '';
      });

      // Create the DOC content
      const docContent = `
        <html>
          <head>
            <style>
              body {
                font-family: Arial, sans-serif;
                background-color: #fff;
                color: #000;
                margin: 0;
                padding: 10px;
              }
              table {
                width: 100%;
                margin-top: 10px;
              }
              table th, table td {
                border: 1px solid #000;
                padding: 8px 12px;
                text-align: left;
              }
              table th {
                background-color: #ccc;
              }
              table tr:nth-child(even) {
                background-color: #f2f2f2;
              }
              table tr:nth-child(odd) {
                background-color: #e6e6e6;
              }
            </style>
          </head>
          <body>
            <h2>Duty Roster</h2>
            <p><strong>DUTY ROSTER OF PS Shahbad STAFF</strong></p>
            <div>${updatedHtmlContent}</div>
          </body>
        </html>
      `;

      // Convert the content to a Blob and trigger the download
      const blob = new Blob([docContent], { type: "application/msword" });

      saveAs(blob, "DutyRoster.doc");
    } else {
      console.error('Component reference is not available.');
    }
  };
  return (
    <>
    <div className="actions">
        <button onClick={handleSubmit}>Submit</button>
        <button onClick={handleExportPDF}>Export as PDF</button>
        <button onClick={handleExportDOC}>Export as DOC</button>
      </div>
    <div  ref={componentRef}>
    
    <div className="common-container">
      <form onSubmit={handleSubmit} className="duty-form">
        <h2><u>ADMINISTRATIVE DUTY ({formattedDate}) E-CHITTA</u></h2>
        <h3>STRENGTH</h3>
        <table className="table-table">
          <thead>
            <tr>
              <th className="table-th">INSP.</th>
              <th className="table-th">SI</th>
              <th className="table-th">ASI</th>
              <th className="table-th">ASI(FR)</th>
              <th className="table-th">HC</th>
              <th className="table-th">HC(FR)</th>
              <th className="table-th">CT</th>
              <th className="table-th">W/HC</th>
              <th className="table-th">W/CT</th>
            </tr>
            <tr>
              <td className="firstColT1 table-td"><input className="table-input" type="text" defaultValue="3" /></td>
              <td className="firstColT1 table-td"><input className="table-input" type="text" defaultValue="16" /></td>              
              <td className="firstColT1 table-td"><input className="table-input" type="text" defaultValue="3" /></td>              
              <td className="firstColT1 table-td"><input className="table-input" type="text" defaultValue="14" /></td>              
              <td className="firstColT1 table-td"><input className="table-input" type="text" defaultValue="08" /></td>
              <td className="firstColT1 table-td"><input className="table-input" type="text" defaultValue="38" /></td>              
              <td className="firstColT1 table-td"><input className="table-input" type="text" defaultValue="17" /></td>              
              <td className="firstColT1 table-td"><input className="table-input" type="text" defaultValue="06" /></td>              
              <td className="firstColT1 table-td"><input className="table-input" type="text" defaultValue="08" /></td>                
            </tr>
          </thead>
        </table>
        <br />
        <div> 
        <table className="table-table">
  <tbody>
    <tr>
      <td className="firstColT1 table-td">1</td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="SHO" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="INSP. ASHOK KUMAR" /></td>
      <td className="table-td" colSpan={5}><input className="table-input" type="text" defaultValue="D-I/84" /></td>
      <td className="table-td" colSpan={3} />
    </tr>
    <tr>
      <td className="firstColT1 table-td">2</td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="ATO" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="INSP.PARDEEP KUMAR" /></td>
      <td className="table-td" colSpan={5}><input className="table-input" type="text" defaultValue="D-4853" /></td>
      <td className="table-td" colSpan={3} />
    </tr>
    <tr>
      <td className="firstColT1 table-td">3</td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="BRAVO" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="INSP.MAHENDER KUMAR" /></td>
      <td className="table-td" colSpan={5}><input className="table-input" type="text" defaultValue="D-4546" /></td>
      <td className="table-td" colSpan={3} />
    </tr>
    <tr>
      <td className="firstColT1 table-td"><input className="table-input" type="text" defaultValue="S.N" /></td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="NATURE OF DUTY" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="8AM TO 4 PM" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={5}><input className="table-input" type="text" defaultValue="4 PM TO 12 AM" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="12 PM TO 8 AM" style={{ fontWeight: 'bold' }}/></td>
    </tr>
    <tr>
      <td className="firstColT1 table-td">1</td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="DUTY OFFICER" style={{ fontWeight: 'bold' }} /></td>
      <td className="table-td" colSpan={3}><textarea className="styled-textarea" defaultValue="W/ASI SHEELAWATI 6164/RD" /></td>
      <td className="table-td" colSpan={5}><input className="table-input" type="text" defaultValue="HC RAKESH 2285/OND" /></td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="ASI SURENDER 931/OND" /></td>
    </tr>
    <tr>
      <td className="firstColT1 table-td">2</td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="D.D. WRITER" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={3}><textarea className="styled-textarea" defaultValue="HC NEETU 1511/OND" /></td>
      <td className="table-td" colSpan={5}><textarea className="styled-textarea" defaultValue="CT SONIA 1685/OND" /></td>
      <td className="table-td" colSpan={3}><textarea className="styled-textarea" defaultValue="W/HC SOMOLATA 1581/OND" /></td>
    </tr>
    <tr>
      <td className="table-td" rowSpan={3}>3</td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="SENTRY DUTY" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="CT KAPTAN 1914/OND" /></td>
      <td className="table-td" colSpan={5}><input className="table-input" type="text" defaultValue="CT PARDEEP 2494/OND" /></td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="CT JAGDISH 2133/OND" /></td>
    </tr>
    <tr>
      <td className="table-td" colSpan={3} rowSpan={2}><input className="table-input" type="text" defaultValue="LOCK UP SENTRY" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={6}><input className="table-input" type="text" defaultValue="8AM TO 8 PM" /></td>
      <td className="table-td" colSpan={5}><input className="table-input" type="text" defaultValue="8 PM TO 8 AM" /></td>
    </tr>
    <tr>
      <td className="table-td" colSpan={6}> <input className="table-input" type="text" defaultValue=""/> </td>
      <td className="table-td" colSpan={5}> <input className="table-input" type="text" defaultValue=""/> </td>
    </tr>
    <tr>
      <td className="firstColT1 table-td">4</td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="READER/DAK DUTY" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={11}><textarea className="styled-textarea" defaultValue={`SI SATPAL 4974/D\nHC HEMANT 2575/OND`} /></td>
    </tr>
    <tr>
      <td className="firstColT1 table-td">5</td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="DEPLOYMENT OFFICER/ARREST INFORMATION OFFICER" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={11}><input className="table-input" type="text" defaultValue="ASI RAM KARAN 318/OND" /></td>
    </tr>
    <tr>
      <td className="firstColT1 table-td">6</td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="MHC(M) GP" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={6}><input className="table-input" type="text" defaultValue="HC SATISH 1995/OND" /></td>
      <td className="table-td" colSpan={5} />
    </tr>
    <tr>
    <td className="firstColT1 table-td">7</td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="MHC(M) CP" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={6}><input className="table-input" type="text" defaultValue="HC BHAWAR 2749/OND" /></td>
      <td className="table-td" colSpan={5}><input className="table-input" type="text" defaultValue="CT RAVI 2000/OND" /></td>
    </tr>
    <tr>
    <td className="firstColT1 table-td">8</td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="MHC®" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={6}><input className="table-input" type="text" defaultValue="HC TUSHAR DAHIYA 2159/OND" /></td>
      <td className="table-td" colSpan={5}>
  <textarea 
    className="styled-textarea" 
    defaultValue={`SI KRISHAN 703/OND\nCT MOHINDER 1908/OND\nCT DINESH 2232/OND\nW/CT POOJA 1483/OND`} 
    rows={4}
  />
</td>

    </tr>
    <tr>
      <td className="firstColT1 table-td">9</td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="V-B" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={11}><textarea className="styled-textarea" defaultValue="HC MUNSHI LAL 2610/OND, HC RAJESH 1900/OND, W/HC KAVITA 1579/OND, HC KAPIL 2445/OND" /></td>
    </tr>
    <tr>
      <td className="firstColT1 table-td">10</td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="CCTNS" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={11}><textarea className="styled-textarea" defaultValue={`W/CT SUMAN 1583/OND ( IC )\nW/HC KAVITA 1500/OND\nW/CT SONIA 1612/OND`} rows={3} /></td>
    </tr>
    <tr>
      <td className="firstColT1 table-td">11</td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="CRACK TEAM/SPECIAL STAFF" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={11}><input className="table-input" type="text" defaultValue="HC ANIRUDH 105/OND" /></td>
    </tr>
    <tr>
      <td className="firstColT1 table-td">12</td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="PO TEAM" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={11} />
    </tr>
    <tr>
      <td className="firstColT1 table-td">13</td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="CALL LETTER" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={11}><input className="table-input" type="text" defaultValue="CT SURAJ 2237/OND" /></td>
    </tr>
    <tr>
      <td className="firstColT1 table-td">14</td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="ANTI- BURGLARY TEAM" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={11}><textarea className="styled-textarea" defaultValue="HC VIKRAM 2514/OND, CT GOURAV 2561/OND" /></td>
    </tr>
    <tr>
      <td className="firstColT1 table-td">15</td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="CDR ANALYSIS" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={11}><input className="table-input" type="text" defaultValue="HC SUDHIR 235/OND" /></td>
    </tr>
    <tr>
      <td className="firstColT1 table-td">16</td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="J-1 RESERVE FORCE" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={11}><textarea className="styled-textarea" defaultValue="ASI BIJENDER 154/OND, HC PARDEEP 2390/OND, ASI RAMESH 647/OND, HC NEERAJ 913/OND" /></td>
    </tr>
    <tr>
      <td className="table-td" rowSpan={2}>17</td>
      <td className="table-td" colSpan={3} rowSpan={2}><input className="table-input" type="text" defaultValue="TEMP. ATTACH" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={11}><input className="table-input" type="text" defaultValue="Court NO.114 - CT SANDEEP 2345/OND" /></td>
    </tr>
    <tr>
      <td className="table-td" colSpan={11}><input className="table-input" type="text" defaultValue="JT.CP (NR) - HC SANDEEP No.379/OND" /></td>
    </tr>
    <tr>
      <td />
      <td className="table-td" colSpan={3} />
      <td className="table-td" colSpan={11}><input className="table-input" type="text" defaultValue="ACP OFFICE BAWANA - HC KULDEEP 2043/OND 113A (26/09)" /></td>
    </tr>
    <tr>
      <td />
      <td className="table-td" colSpan={3} />
      <td className="table-td" colSpan={11}><input className="table-input" type="text" defaultValue="J-2 OFFICE – CT ANKIT 2569/OND" /></td>
    </tr>
    <tr>
      <td className="firstColT1 table-td">18</td>
      <td className="table-td" colSpan={3}><textarea className="styled-textarea" defaultValue="CENTRAL MALKHANA CCTV CAMERA" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={6}><input className="table-input" type="text" defaultValue=""/></td>
      <td className="table-td" colSpan={5}><input className="table-input" type="text" defaultValue=""/></td>
    </tr>
    <tr>
      <td className="firstColT1 table-td">19</td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="PUBLIC FACILIATION DESK" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={6}><input className="table-input" type="text" defaultValue="10 AM TO 06 PM" /></td>
      <td className="table-td" colSpan={5} />
    </tr>
    <tr>
      <td className="firstColT1 table-td">20</td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="DRIVER & OPR. OF SHO" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={6}><input className="table-input" type="text" defaultValue="HC KARAN 2225/OND" /></td>
      <td className="table-td" colSpan={5}><input className="table-input" type="text" defaultValue="DUTY OFF -" /></td>
    </tr>
    <tr>
      <td className="firstColT1 table-td"rowSpan={2}>21</td>
      <td className="table-td" colSpan={3} rowSpan={2}><input className="table-input" type="text" defaultValue="W/OPP. 52" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="8AM TO 3PM" /></td>
      <td className="table-td" colSpan={5}><input className="table-input" type="text" defaultValue="3 PM TO 10PM" /></td>
      <td className="table-td" colSpan={3}><input className="table-input" type="text" defaultValue="10PM TO 8AM" /></td>
    </tr>
    <tr>
      <td className="table-td" colSpan={3}> <input className="table-input" type="text" defaultValue=""/> </td>
      <td className="table-td" colSpan={5}> <input className="table-input" type="text" defaultValue=""/> </td>
      <td className="table-td" colSpan={3}> <input className="table-input" type="text" defaultValue=""/> </td>
    </tr>
    <tr>
      <td className="firstColT1 table-td">22</td>
      <td className="table-td" colSpan={2}><input className="table-input" type="text" defaultValue="CASUAL LEAVE" style={{ fontWeight: 'bold' }}/></td>
      <td className="table-td" colSpan={12}>
        <textarea className="styled-textarea" defaultValue="SI SATISH 4531/D(16/12), HC PARDEEP 2388(02/12), SI BALWAN 4877/D(02/12), ASI RAM NARAYAN 1053/OND(16/12), HC AJEET 2298/OND(03/12), HC RAVI 2592/OND(02/12), HC SURENDER 2551/OND(04/12), HC ASHISH 442/OND(04/12), CT MOHINDER 1908/OND(04/12), HC ARVIND 2210/OND(04/12), HC SATENDER 375/OND(04/12), W/HC KAVITA 1579/OND(04/12), HC VIKRAM 2514/OND(3/12)" rows={5}/>
      </td>
    </tr>
    <tr>
  <td className="firstColT1 table-td">23</td>
  <td className="table-td" colSpan={2}><input className="table-input" type="text" defaultValue="EL/CCL" style={{ fontWeight: 'bold' }}/></td>
  <td className="table-td" colSpan={12}>
    <textarea className="styled-textarea" defaultValue="CT GAURAV 2086/OND(16/12),W/CT PRIYA 1488/OND(09/12),W/HC KAMLESH 
1625/OND(09/12),CT RAVI 2000/OND(16/12)HC HARISH 2182/OND(12/12)" />
  </td>
</tr>
<tr>
  <td className="firstColT1 table-td">24</td>
  <td className="table-td" colSpan={2}><input className="table-input" type="text" defaultValue="MEDICAL REST" style={{ fontWeight: 'bold' }}/></td>
  <td className="table-td" colSpan={12}>
    <textarea className="styled-textarea" defaultValue="W/HC SUNITA1567/OND (02/12), ASI MANIK SATHE 911/OND(27/11),ASI ANAND 
136/OND(01/12)" />
  </td>
</tr>
<tr>
  <td className="firstColT1 table-td">25</td>
  <td className="table-td" colSpan={2}><input className="table-input" type="text" defaultValue="ABSENT" style={{ fontWeight: 'bold' }}/></td>
  <td className="table-td" colSpan={12}>
    <textarea className="styled-textarea" defaultValue="W/SI KHUSBOO-D/6435(DD NO.-50A 15/12/22) ,ASI RAMMLU 983/OND(28/09)" />
  </td>
</tr>
<tr>
  <td className="firstColT1 table-td">26</td>
  <td className="table-td" colSpan={2}><input className="table-input" type="text" defaultValue="W/IO" style={{ fontWeight: 'bold' }}/></td>
  <td className="table-td" colSpan={12}>
    <textarea className="styled-textarea" defaultValue="SI SANGEETA 5099/D,SI PINKI D-1802,W/SI AMBIKA D-1809,W/ASI SEEMA 5095/D" />
  </td>
</tr>
<tr>
  <td className="firstColT1 table-td">27</td>
  <td className="table-td" colSpan={2}><input className="table-input" type="text" defaultValue="D-COURSE" style={{ fontWeight: 'bold' }}/></td>
  <td className="table-td" colSpan={12}>
    <textarea className="styled-textarea" defaultValue="PSI ROHAN DABAS D-3025" />
  </td>
</tr>
<tr>
  <td className="firstColT1 table-td">28</td>
  <td className="table-td" colSpan={2}><input className="table-input" type="text" defaultValue="DUTY OFF" style={{ fontWeight: 'bold' }}/></td>
  <td className="table-td" colSpan={12}>
    <textarea className="styled-textarea" defaultValue="W/CT LALITA 1646/OND, HC VIRENDER 343/OND" />
  </td>
</tr>
<tr>
  <td className="firstColT1 table-td">29</td>
  <td className="table-td" colSpan={2}><input className="table-input" type="text" defaultValue="DUTY REST" style={{ fontWeight: 'bold' }}/></td>
  <td className="table-td" colSpan={12}>
    <textarea className="styled-textarea" defaultValue="W/CT MONIKA 1676/OND, CT DINESH 2024/OND,HC PARDEEP 2471/OND" />
  </td>
</tr>
<tr>
  <td className="firstColT1 table-td">30</td>
  <td className="table-td" colSpan={2}><input className="table-input" type="text" defaultValue="ARR.IN TRAFFICE" style={{ fontWeight: 'bold' }}/></td>
  <td className="table-td" colSpan={12}>
    <textarea className="styled-textarea" defaultValue="HC OMAN 2268/OND" />
  </td>
</tr>
<tr>
  <td className="firstColT1 table-td">31</td>
  <td className="table-td" colSpan={2}><input className="table-input" type="text" defaultValue="R D PARADE" style={{ fontWeight: 'bold' }}/></td>
  <td className="table-td" colSpan={12}>
    <textarea className="styled-textarea" defaultValue="PSI ROHAN DABAS-D-3025" />
  </td>
</tr>
<tr>
  <td className="firstColT1 table-td">32</td>
  <td className="table-td" colSpan={2}><input className="table-input" type="text" defaultValue="ARRANGMENT" style={{ fontWeight: 'bold' }}/></td>
  <td className="table-td" colSpan={12}>
    <textarea className="styled-textarea" defaultValue="DAY SHIFT-HC DEEPAK 2137/OND,HC MD.AIYB 385/OND, CT SATENDER 
2493/OND, CT SURAJ 2227/OND 
NIGHT SHIFT-HC ANUJ 2495/OND, HC DEVENDER 198/OND" rows={3} />
  </td>
</tr>
<tr>
  <td className="firstColT1 table-td"rowSpan={3}>33</td>
  <td className="table-td" colSpan={2} rowSpan={3}><input className="table-input" type="text" defaultValue="NAIB COURT" style={{ fontWeight: 'bold' }}/></td>
  <td className="table-td" colSpan={12}>
    <input className="table-input" type="text" defaultValue="HIGH COURT- SI SATISH 4531-D" />
  </td>
 
</tr>
<tr>
  <td className="table-td" colSpan={12}>
  <input className="table-input" type="text" defaultValue="ROHINI COURT – ASI VATS RAJ 736/OND " />
  </td>
</tr>
<tr>
  <td className="table-td" colSpan={12}>
  <input className="table-input" type="text" defaultValue="ROOM NO.-114-HC ARVIND 2210/OND" />
  </td>
</tr>
  </tbody>
        </table>
        <br />
        </div>
        <div>
        <h3><u>EMERGENCY DUTY </u></h3>
        <table className="table-table">

            <tbody>
                <tr>
                    <td className="table-td" colSpan="7" style={{textAlign: "center"}}><strong>DAY SHIFT</strong></td>
                    <td className="table-td" colSpan="7" style={{textAlign: "center"}}><strong>NIGHT SHIFT</strong></td>
                </tr>
                <tr>
            <td className="table-td"><strong>S.NO</strong></td>
            <td className="table-td" colSpan="3" style={{ width: '24%' }}><strong>EMERGENCY OFFICER</strong></td>
            <td className="table-td" colSpan="3" style={{ width: '24%' }}><strong>EMERGENCY STAFF</strong></td>
            <td className="table-td"><strong>S.NO</strong></td>
            <td className="table-td" colSpan="3" style={{ width: '24%' }}><strong>EMERGENCY OFFICER</strong></td>
            <td className="table-td" colSpan="3" style={{ width: '28%' }}><strong>EMERGENCY STAFF</strong></td>
        </tr>

        <tr>
            <td className="table-td"><strong>1</strong></td>
            <td className="table-td" colSpan="3" style={{ width: '24%' }}><input className="table-input" type="text" defaultValue="SI SURENDER D-6749" /></td>
            <td className="table-td" colSpan="3" style={{ width: '24%' }}><input className="table-input" type="text" defaultValue="CT GAURAV 2561/OND" /></td>
            <td className="table-td"><strong>1</strong></td>
            <td className="table-td" colSpan="3" style={{ width: '24%' }}><input className="table-input" type="text" defaultValue="SI TEJ SINGH D-5092" /></td>
            <td className="table-td" colSpan="3" style={{ width: '28%' }}><input className="table-input" type="text" defaultValue="HC NARENDER 2452/OND" /></td>
        </tr>

        <tr>
            <td className="table-td"><strong>2</strong></td>
            <td className="table-td" colSpan="3" style={{ width: '24%' }}><input className="table-input" type="text" defaultValue="ASI ABHINAV 141/OND" /></td>
            <td className="table-td" colSpan="3" style={{ width: '24%' }}><input className="table-input" type="text" defaultValue="CT GAURAV 2561/OND" /></td>
            <td className="table-td"><strong>2</strong></td>
            <td className="table-td" colSpan="3" style={{ width: '24%' }}><input className="table-input" type="text" defaultValue="ASI INDER 469/F" /></td>
            <td className="table-td" colSpan="3" style={{ width: '28%' }}><input className="table-input" type="text" defaultValue="HC NARENDER 2452/OND" /></td>
        </tr>

        <tr>
            <td className="table-td"><strong>3</strong></td>
            <td className="table-td" colSpan="3" style={{ width: '24%' }}><input className="table-input" type="text" defaultValue="HC PARDEEP 101/OND" /></td>
            <td className="table-td" colSpan="3" style={{ width: '24%' }}><input className="table-input" type="text" defaultValue="CT GAURAV 2561/OND" /></td>
            <td className="table-td"><strong>3</strong></td>
            <td className="table-td" colSpan="3" style={{ width: '24%' }}><input className="table-input" type="text" defaultValue="HC AJAY 2071/OND" /></td>
            <td className="table-td" colSpan="3" style={{ width: '28%' }}><input className="table-input" type="text" defaultValue="HC NARENDER 2452/OND" /></td>
        </tr>

        <tr>
            <td className="table-td"><strong>4</strong></td>
            <td className="table-td" colSpan="3" style={{ width: '24%' }}><input className="table-input" type="text" defaultValue="HC VIKASH 380/OND" /></td>
            <td className="table-td" colSpan="3" style={{ width: '24%' }}><input className="table-input" type="text" defaultValue="CT GAURAV 2561/OND" /></td>
            <td className="table-td"><strong>4</strong></td>
            <td className="table-td" colSpan="3" style={{ width: '24%' }}><input className="table-input" type="text" defaultValue="HC AJEET 2282/OND" /></td>
            <td className="table-td" colSpan="3" style={{ width: '28%' }}><input className="table-input" type="text" defaultValue="HC NARENDER 2452/OND" /></td>
        </tr>


                <tr>
                    <td className="table-td" colSpan="4"><strong>RAPE & POCSO EMERGENCY OFFICER</strong></td>
                    <td className="table-td" colSpan="4">
            <textarea defaultValue="W/ASI SEEMA 5095/D" class="styled-textarea" rows={1}></textarea>
        </td>
        <td className="table-td" colSpan="6">
            <textarea defaultValue="W/ASI SEEMA 5095/D" class="styled-textarea" rows={1}></textarea>
        </td>
                </tr>
            </tbody>
        </table>
        </div>
        <br />
        <div>
          <h3><u>ERV & QRT DUTY</u></h3>
          <table className="table-table">
            <tr>
              <td className="table-td" style={{width:"3%"}}> <strong>SR.NO.</strong> </td>
              <td className="table-td" style={{width:"30%"}}> <strong>TYPE OF VEHICE</strong> </td>
              <td className="table-td" style={{width:"30%"}}> <strong>DEPLOYED STAFF</strong> </td>
              <td className="table-td" style={{width:"30%"}}> <strong>DUTY OFF</strong> </td>
            </tr>
            <tr>
            <td className="table-td" style={{width:"3%"}}> <strong>1</strong> </td>
            <td className="table-td" style={{width:"30%"}}> <strong>ERV(DL1CZ2525)</strong> </td>
            <td className="table-td" style={{width:"30%"}}><textarea className="styled-textarea" defaultValue={`ASI RAM CHANDER 951/OND\nCT SATPAL 4990 /DHG`} />  </td>
            <td className="table-td" style={{width:"30%"}}><textarea className="styled-textarea" defaultValue={`ASI ANIL 803/OND\nCT MANISH 5086/OND `} />   </td>
            </tr>
            <tr>
            <td className="table-td" style={{width:"3%"}}> <strong>2</strong> </td>
            <td className="table-td" style={{width:"30%"}}> <strong>QRT (DL1CAG5243)</strong> </td>
            <td className="table-td" style={{width:"30%"}}>      <input className="table-input" type="text" defaultValue="CT PARVEEN /DHG" />
        </td>
            <td className="table-td" style={{width:"30%"}}>    <input className="table-input" type="text" defaultValue="ASI NARESH 10000/DAP" />
            </td>
            </tr>
          </table>
        </div>
        <br />
        <div>
          <h3><u>MORNING BEAT PETROLLING</u></h3>
          <table className="table-table">
            <tr>
              <td className="table-td" style={{width:"3%"}}> <strong>SR.NO.</strong> </td>
              <td className="table-td" style={{width:"30%"}}> <strong>MOTOR CYCLE NO.</strong> </td>
              <td className="table-td" style={{width:"30%"}}> <strong>PLACE</strong> </td>
              <td className="table-td" style={{width:"30%"}}> <strong>DEPLOYED STAFF</strong> </td>
            </tr>
            <tr>
            <td className="table-td" style={{width:"3%"}} rowSpan={2}> <strong>1</strong> </td>
            <td className="table-td" style={{width:"30%"}} rowSpan={2}> <strong>DL1SAF1729</strong> </td>
            <td className="table-td" style={{width:"30%"}} rowSpan={2}> <strong>SECTOR -11</strong> </td>
            <td className="table-td" style={{width:"30%"}}> <input className="table-input" type="text" defaultValue="HC SANJEEV 2433/OND" />  </td>
            </tr>
            <tr>
            <td className="table-td" style={{width:"30%"}}>    <input className="table-input" type="text" defaultValue="CT RAVINDER 2485/OND" />
            </td>
            </tr>
          </table>
        </div>
        <br />
        <div>
        <h3><u>BEAT WISE DEPLOYEMENT IN POLICE STATION SHAHBAD DAIRY DT {formattedDate}</u></h3>

<table className="table-table">
<thead>
  <th className="table-th">Beat</th>
  <th className="table-th">Location</th>
  <th className="table-th">Staff Names</th>
</thead>
  <tbody>
  {data.map((row, index) => {
  const isHighlighted = beatsForCurrentDay.includes(row.beat); // Check if beat is in the list of highlighted beats
  return (
    <tr key={index} style={{ backgroundColor: isHighlighted ? 'pink' : 'transparent' }}>
      
      <td className="table-td" style={{width:"10%"}}>
        <input className="table-input" type="text" style={{ fontWeight: "bold" }}
          value={row.beat}
          onChange={(e) => handleTextInputChange(e, index, 'beat')}
        />
      </td>
      <td className="table-td" style={{width:"30%"}}>
        <textarea
          style={{ fontWeight: "bold" }}
          className="styled-textarea"
          value={row.location}
          rows="4"  // Adjusting height for readability
          onChange={(e) => handleTextInputChange(e, index, 'location')}
        />
      </td>
      <td className="table-td">
        <textarea
          className="styled-textarea"
          value={row.staffNames.join('\n')}
          rows={data[index].staffNames.length}  // Adjusting the height for readability
          onChange={(e) => {
            const updatedData = [...data];
            updatedData[index].staffNames = e.target.value.split('\n');
            setData(updatedData);
          }}
        />
      </td>
      
    </tr>
  );
})}

  </tbody>

</table>



        </div>
        <br />
        <div>
          <h3><u>BEAT PATROLLING DT {formattedDate}</u></h3>
         
          <table className="table-table">
  <tbody>
    <tr>
      <td className="table-td" colSpan="2"><strong>BEAT NO.</strong></td>
      <td className="table-td" colSpan="2"><strong>M/CY NO.</strong></td>
      <td className="table-td" colSpan="2"><strong>09AM TO 05PM</strong></td>
      <td className="table-td" colSpan="2"><strong>06PM TO 12PM</strong></td>
      <td className="table-td"><strong>12PM TO 06 AM</strong></td>
    </tr>

    {/* Row 1 */}
    <tr>
          <td className="table-td" colSpan="2" rowSpan="2">BEAT NO. 1</td>
          <td className="table-td" colSpan="2" rowSpan="2">DL1SAF3781</td>
          <td className="table-td" colSpan="2">
            <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolDay', 'beat')}>
              <option value="None">None</option>
              {data[0].staffNames.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </td>
          <td className="table-td" colSpan="2">
            <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolEvening', 'beat')}>
              <option value="None">None</option>
              {data[0].staffNames.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </td>
          <td className="table-td">
            <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolNight', 'beat')}>
              <option value="None">None</option>
              {data[0].staffNames.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </td>
        </tr>
<tr>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolDay', 'beat')}>
      <option value="None">None</option>
      {data[0].staffNames.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
    </select>
  </td>
  <td className="table-td" colSpan="2">
  <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolEvening', 'beat')}>
      <option value="None">None</option>
      {data[0].staffNames.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
    </select>
  </td>
  <td className="table-td">
  <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolNight', 'beat')}>
      <option value="None">None</option>
      {data[0].staffNames.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
    </select>
  </td>
</tr>


    {/* Row 2 */}
    <tr>
  <td className="table-td" colSpan="2" rowSpan="2">BEAT NO. 2</td>
  <td className="table-td" colSpan="2" rowSpan="2">DL1SAF1728</td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolDay', 'beat')}> 
      <option value="None">None</option> 
      {data[1].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolEvening', 'beat')}> 
      <option value="None">None</option> 
      {data[1].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolNight', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[1].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
</tr>
<tr>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolDay', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[1].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolEvening', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[1].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolNight', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[1].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
</tr>



    {/* Row 3 */}
    <tr>
  <td className="table-td" colSpan="2" rowSpan="2">BEAT NO. 3</td>
  <td className="table-td" colSpan="2" rowSpan="2">DL1SAF2586</td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolDay', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[2].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolEvening', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[2].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolNight', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[2].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
</tr>
<tr>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolDay', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[2].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolEvening', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[2].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolNight', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[2].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
</tr>

    {/* Row 4 */}
    <tr>
  <td className="table-td" colSpan="2" rowSpan="2">BEAT NO. 4</td>
  <td className="table-td" colSpan="2" rowSpan="2">DL1SAF1724</td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolDay', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[3].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolEvening', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[3].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolNight', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[3].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
</tr>
<tr>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolDay', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[3].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolEvening', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[3].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolNight', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[3].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
</tr>


    {/* Row 5 */}
    <tr>
  <td className="table-td" colSpan="2" rowSpan="2">BEAT NO. 5</td>
  <td className="table-td" colSpan="2" rowSpan="2">DL1SAF1721</td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolDay', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[4].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolEvening', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[4].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolNight', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[4].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
</tr>
<tr>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolDay', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[4].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolEvening', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[4].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolNight', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[4].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
</tr>

    {/* Row 6 */}
<tr>
  <td className="table-td" colSpan="2" rowSpan="2">BEAT NO. 6</td>
  <td className="table-td" colSpan="2" rowSpan="2">DL1SAF2621</td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolDay', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[5].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolEvening', 'beat')}>
    <option value="None">None</option> 
      {data[5].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolNight', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[5].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
</tr>
<tr>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolDay', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[5].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolEvening', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[5].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolNight', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[5].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
</tr>

{/* Row 7 */}
<tr>
  <td className="table-td" colSpan="2" rowSpan="2">BEAT NO. 7</td>
  <td className="table-td" colSpan="2" rowSpan="2">DL1SAF2876</td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolDay', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[6].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolEvening', 'beat')}>
    <option value="None">None</option> 
      {data[6].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolNight', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[6].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
</tr>
<tr>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolDay', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[6].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolEvening', 'beat')}>
    <option value="None">None</option> 
      {data[6].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolNight', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[6].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
</tr>

{/* Row 8 */}
<tr>
  <td className="table-td" colSpan="2" rowSpan="2">BEAT NO. 8</td>
  <td className="table-td" colSpan="2" rowSpan="2">DL1SAF3589</td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolDay', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[7].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolEvening', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[7].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolNight', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[7].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
</tr>
<tr>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolDay', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[7].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolEvening', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[7].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolNight', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[7].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
</tr>


   {/* Row 9 */}
<tr>
  <td className="table-td" colSpan="2" rowSpan="2">BEAT NO. 9</td>
  <td className="table-td" colSpan="2" rowSpan="2">DL1SAF4921</td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolDay', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[8].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolEvening', 'beat')}>
    <option value="None">None</option> 
      {data[8].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolNight', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[8].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
</tr>
<tr>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolDay', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[8].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolEvening', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[8].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolNight', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[8].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
</tr>

{/* Row 10 */}
<tr>
  <td className="table-td" colSpan="2" rowSpan="2">BEAT NO. 10</td>
  <td className="table-td" colSpan="2" rowSpan="2">DL1SAF2930</td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolDay', 'beat')}>
    <option value="None">None</option> 
      {data[9].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolEvening', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[9].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolNight', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[9].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
</tr>
<tr>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolDay', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[9].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolEvening', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[9].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td">
    <select className="table-select" defaultValue="None" onChange={(e) => selectedName(e.target.value, 'patrolNight', 'beat')}> {/* Setting default value to "None" */}
      <option value="None">None</option> {/* Adding the "None" option */}
      {data[9].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
</tr>

  </tbody>
</table>


        </div>
        <br />
        <div>
          <h3><u>PICKET DUTY</u></h3>
          <table className="table-table">
              <tbody>
                <tr>
                  <td className="table-td"><strong>S.<br />NO</strong></td>
                  <td className="table-td" colSpan="2" style={{width:"25%"}}><strong>PLACE</strong></td>
                  <td className="table-td" colSpan="2" style={{width:"25%"}}><strong>08 AM TO 04 PM</strong></td>
                  <td className="table-td" colSpan="2" style={{width:"25%"}}><strong>04 PM TO 11 PM</strong></td>
                  <td className="table-td" colSpan="3" style={{width:"25%"}}><strong>11 PM TO 08 AM</strong></td>
                </tr>
                <tr>
  <td className="table-td" rowSpan={2}><strong>1</strong></td>
  <td className="table-td" colSpan="2" rowSpan="2"style={{width:"25%"}}> <strong>PUNJAB SWEET PASSI MARG</strong> </td>
  <td className="table-td" colSpan="2"style={{width:"25%"}}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolDay', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[0].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2"style={{width:"25%"}}>
  <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolEvening', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[0].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{width:"25%"}}>
  <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolNight', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[0].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
</tr>
<tr>
<td className="table-td" colSpan="2" style={{width:"25%"}}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolDay', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[0].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{width:"25%"}}>
  <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolEvening', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[0].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{width:"25%"}}>
  <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolNight', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[0].staffNames.map((name, index) => (
        <option key={index} value={name}>
          {name}
        </option>
      ))}
    </select>
  </td>
</tr>
               
               <tr>
  <td className="table-td" rowSpan={2}><strong>2</strong></td>
  <td className="table-td" colSpan="2" rowSpan="2" style={{ width: "25%" }}><strong>RITHALA METRO</strong></td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolDay', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[1].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolEvening', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[1].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolNight', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[1].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
</tr>
<tr>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolDay', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[1].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolEvening', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[1].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolNight', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[1].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
</tr>

<tr>
  <td className="table-td" rowSpan={2}><strong>3</strong></td>
  <td className="table-td" colSpan="2" rowSpan="2" style={{ width: "25%" }}><strong>PINK BOOTH</strong></td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolDay', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[2].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolEvening', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[2].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolNight', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[2].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
</tr>
<tr>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolDay', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[2].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolEvening', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[2].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolNight', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[2].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
</tr>

<tr>
  <td className="table-td" rowSpan={2}><strong>4</strong></td>
  <td className="table-td" colSpan="2" rowSpan="2" style={{ width: "25%" }}><strong>ST. XAVIOR</strong></td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolDay', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[3].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolEvening', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[3].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolNight', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[3].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
</tr>
<tr>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolDay', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[3].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolEvening', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[3].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolNight', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[3].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
</tr>

<tr>
  <td className="table-td" rowSpan={2}><strong>5</strong></td>
  <td className="table-td" colSpan="2" rowSpan="2" style={{ width: "25%" }}><strong>SECTOR-25</strong></td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolDay', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[4].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolEvening', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[4].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolNight', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[4].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
</tr>
<tr>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolDay', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[4].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolEvening', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[4].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolNight', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[4].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
</tr>

<tr>
  <td className="table-td" rowSpan={2}><strong>6</strong></td>
  <td className="table-td" colSpan="2" rowSpan="2" style={{ width: "25%" }}><strong>SECTOR-29 KHERA NAHAR</strong></td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolDay', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[5].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolEvening', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[5].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolNight', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[5].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
</tr>
<tr>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolDay', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[5].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolEvening', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[5].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolNight', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[5].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
</tr>

<tr>
  <td className="table-td" rowSpan={2}><strong>7</strong></td>
  <td className="table-td" colSpan="2" rowSpan="2" style={{ width: "25%" }}><strong>RAJ MANDIR (NEAR TRUE VALUE)</strong></td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolDay', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[6].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolEvening', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[6].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolNight', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[6].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
</tr>
<tr>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolDay', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[6].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolEvening', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[6].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
  <td className="table-td" colSpan="2" style={{ width: "25%" }}>
    <select className="table-select" defaultValue="None" onChange={(e) => selectedNamePicket(e.target.value, 'patrolNight', 'picket')}> 
      <option value="None">None</option>
      {Picketdata[6].staffNames.map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))}
    </select>
  </td>
</tr>


              </tbody>
            </table>
        </div>

    </form>
    </div>
    </div>
    </>
  );
}

export default DutyForm;
