import React, { useRef, useState,useEffect } from "react";
import DELHI_POLICE_LOGO from '../../assets/DELHI_POLICE_NIA_DUTY_CHART_LOGO.jpg';
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";
import { API_URL } from '../config/config';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './DutyFormNIA.css';
import data from './data.json';


const Data=data;

function filterByPS(data, psValue) {
  return data.filter(entry => entry.PS === psValue);
}

const DutyRosterForm = () => {
  
  const componentRef = useRef(); 
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-GB");
  const [shoName, setShoName] = useState('INSP. MANOJ KUMAR');
  const dayNumber = currentDate.getDay();

// Convert the number to the name of the day
const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const currentDay = daysOfWeek[dayNumber];

const NIAData =filterByPS(Data , 'NARELA INDUSTRIAL AREA')
// Filter beats for current day with Count > 8
const beatsForCurrentDay= NIAData
    .filter(entry => entry.DayOfWeek === currentDay && parseInt(entry.Count) > 6)
    .map(entry => entry.BEAT);

    
    

  const [rosterData, setRosterData] = useState({
    sanctionedStrengthInsp: '',
    sanctionedStrengthSI: '',
    sanctionedStrengthASI: '',
    sanctionedStrengthHC: '',
    sanctionedStrengthCt: '',
    presentStrengthInsp: '02',
    presentStrengthSI: '13+3 = 16',
    presentStrengthASI: '22+2 = 24',
    presentStrengthHC: '47+4 = 51',
    presentStrengthCt: '23+5 = 28',
    shortageInsp: '',
    shortageSI: '',
    shortageASI: '',
    shortageHC: '',
    shortageCt: '',
    casualLeaveInsp: '-',
    casualLeaveSI: '02',
    casualLeaveASI: '04',
    casualLeaveHC: '06',
    casualLeaveCt: '03+01',
    earnedLeaveInsp: '-',
    earnedLeaveSI: '-',
    earnedLeaveASI: '01+01',
    earnedLeaveHC: '01',
    earnedLeaveCt: '01',
    absentInsp: '',
    absentSI: '',
    absentASI: '',
    absentHC: '01',
    absentCt: '02',
    medicalRestInsp: '',
    medicalRestSI: '',
    medicalRestASI: '',
    medicalRestHC: '02',
    medicalRestCt: '02',
    outStationInsp: '',
    outStationSI: '',
    outStationASI: '',
    outStationHC: '01',
    outStationCt: '',
    arrangementInsp: '01',
    arrangementSI: '01',
    arrangementASI: '01',
    arrangementHC: '03',
    arrangementCt: '02+01',
    attachStaffInsp: '01',
    attachStaffSI: '01',
    attachStaffASI: '01',
    attachStaffHC: '',
    attachStaffCt: '',
    dutyPresentInsp: '',
    dutyPresentSI: '',
    dutyPresentASI: '',
    dutyPresentHC: '',
    dutyPresentCt: '',
    totalInsp: '',
    totalSI: '',
    totalASI: '',
    totalHC: '',
    totalCt: '',
    presentHomeGuards: '10'
  });
  const [dutyRosterData, setDutyRosterData] = useState({
    row1_8to4: 'ASI RAJENDER 779/OND',
    row1_4to12: 'HC RAJESH 324/OND',
    row1_12to8: 'WHC SARITA 1557/OND',
    row2_8to4: '',
    row2_4to12: '',
    row2_12to8: '',
    row3_8to4: ['CT ROHIT 2047/OND (MAIN GATE)','CT SHAMSHER 2309/OND (LOCK UP)'],
    // row3_4to12: '',
    row3_12to8: 'CT. JITENDER 2408/OND (8PM TO 8AM)',
    row4_8to4: 'SI PRADEEP 198-D',
    row4_4to12: 'W/CT JYOTI 1519/OND',
    row4_12to8: 'SI MADAN 635/OND',
    row5_8to4: 'ASI NARENDER 1057/OND',
    row5_4to12: 'WHC BABY 1537/OND ',
    row5_12to8: 'CT. RAVINDER 2040/OND PP M.VIHAR',
    row6_8to4: 'HC JITENDER 411/OND, W/CT SUSHIL  1675, CT SHAMSHER 2309/OND, W/CT JYOTI 1569/OND',
    // row6_4to12: '',
    // row6_12to8: '',
    row7_8to4: 'HC ASHOK 2613/OND, HC RAMKISHAN 2116/OND',
    // row7_4to12: '',
    // row7_12to8: '',
    row8_8to4: 'HC MANJEET 2135/OND, HC DEVENDER 241/OND',
    // row8_4to12: '',
    // row8_12to8: '',
    row9_8to4: 'HC RAVINDER 2468/OND',
    // row9_4to12: '',
    // row9_12to8: '',
    row10_8to4: 'SI RANBIR NO. D-1727',
    // row10_4to12: '',
    // row10_12to8: '',
    row11_8to4: 'WHC BIMLA 1554/OND, (RITU, PANKAJ, KUNAL, HIMANSHU)',
    // row11_4to12: '',
    // row11_12to8: '',
    row12_8to4: '10AM TO 6PM',
    row12_4to12: ' PINK BOOTH',
    // row12_12to8: '',
    row13_8to4: '10AM TO 6PM',
    row13_4to12: '',
    row13_12to8: '',
    row14_8to4: '',
    // row14_4to12: '',
    // row14_12to8: '',
    row15_8to4: 'CT. KAILASH 2239/OND',
    // row15_4to12: '',
    // row15_12to8: '',
    row16_8to4: 'SI RAJESH, HC KRISHAN 226/OND, HC ASHOK 2613/OND, CT MUKESH 2134/OND, ASI MANOJ 993/OND, ASI RAVINDER 236/OND, SI VINOD, CT. ASHWANI 2498/OND, HC AMIT 2609/OND, CT. RAVINDER 2040/OND, ASI KRISHAN 785/OND,SI RAVI RANA',
    row16_4to12: 'WASI VIJAY RANI, W/ASI GEETA 1362/OND, ASI BIJENDER 234/OND, ASI SANTOSH 659/OND',
    // row16_12to8: '',
    row17_8to4: '',
    // row17_4to12: '',
    // row17_12to8: '',
    row18_8to4: '',
    // row18_4to12: '',
    // row18_12to8: '',
    row19_8to4: 'HC MOHIT 252/OND, W/HC KAMLESH 1535/OND',
    // row19_4to12: '',
    // row19_12to8: '',
    row20_8to4: '',
    // row20_4to12: '',
    // row20_12to8: ''
  });
  const [dutyContainer, setDutyContainer] = useState({
    ppmetro_col1: `SI RAVI RANA I/C PP
ASI RAVINDER 236/OND
ASI MANOJ 997/OND
ASI KULBIR 955/OND
HC AMIT 2609/OND
HC BISHRAM 2473/OND`,

    ppmetro_col2: `HC ASHOK 2613/OND
HC MAHASHER ALI 2490/OND
CT RAVINDER 2040/OND
CT RAMESH 2068/OND
CT MUKESH 2134/OND
CT AYUSH 2033/OND`,

    ladiesStaff_col1: `WSI ANJU D-3062
WPSI ANJALI D-3489
PSI JYOTI D-3495
WASI GEETA 1362/OND
ASI VIJAY RANI 1356/OND
WHC BIMLA 1554/OND I/C CCTNS
W/HC BABY 1585/OND`,

    ladiesStaff_col2: `WHC KAMLESH 1535/OND
WHC SARITA 1557/OND
WCT RACHANA 1624/OND
WCT SUSHIL 1675/OND CCTNS
WCT KAVITA 1694/OND
WCT JYOTI 1569/OND
W/CT JYOTI 1519/OND READER`,

    emegencyStaff_col1: `DAY -DSIIDC-
SI VISHU
HC JITENDER 2034/OND

SEC 5 -
HC DEEPAK 1972/OND
HC RAJESH 324/OND

M. VIHAR -

JJC-
ASI KRISHAN 785/OND
CT. SUMIT 2054/OND`,

    emegencyStaff_col2: `NIGHT DSIIDC -
SI DEEPAK LATHWAL D-337

SEC 5 -

M. VIHAR -

JJC -`,
  });
  const [data, setData] = useState([
    {
      division: '1. SI DINESH',
      beat: '01',
      area:`JJ COLONY BAWANA`,
      staffNames: [
        'SI WAZIR SINGH 4366-D',
        'ASI SANTOSH 659/OND',
        'HC SONU MALIK 2489/OND',
        'HC UTTAM 409/OND',
        'CT DUSHYANT 2355/OND',
        'CT ASHWANI 2498/OND',
        'CT VIKRAM 2055/OND',
        'W/HC BABY 1585/OND',
      ],
      callSign: 'NIA 11',
      patrolMorning: 'Select',
      patrolEvening: 'Select',
    },
    {
      // division: '1',
      beat: '02',
      area: `JJ COLONY BAWANA`,
      staffNames: [
        'ASI BALRAJ 210/OND',
        'ASI KRISHAN 785/OND',
        'ASI KAMLESH 233/OND',
        'HC MAHIPAL 358/OND',
        'HC KARAMBIR 2214/OND',
        'CT SUMIT 2054/OND',
        'CT NAVEEN 2427/OND',
        'CT AMARDEEP 2065/OND',
      ],
      callSign:'NIA 12',
      patrolMorning: 'Select',
      patrolEvening: 'Select',
    },
    {
      division: '2. SI RAVI RANA',
      beat: '03',
      area: 'KHERA KHURD',
      staffNames: ['ASI RAVINDER 236/OND', 'HC AMIT 2609/OND', 'HC BISHRAM 2473/OND'],
      callSign: 'NIA 13',
      patrolMorning: 'Select',
      patrolEvening: 'Select',
    },
    {
      // division: '2',
      beat: '04',
      area: 'HOLAMBI',
      staffNames: ['HC SANJEET 2484/OND', 'CT RAVINDER 2040/OND'],
      callSign: 'NIA 14',
      patrolMorning: 'Select',
      patrolEvening: 'Select',
    },
    {
      // division: '2',
      beat: '05',
      area: 'METRO VIHAR',
      staffNames: [
        'ASI MANOJ 993/OND',
        'HC MAHASHER ALI 2490',
        'HC ASHOK 2613/OND',
        'CT RAMESH 2068/OND',
        'CT AYUSH 2033/OND',
        'CT MUKESH 2134/OND',
      ],
      callSign: 'NIA 15',
      patrolMorning: 'Select',
      patrolEvening: 'Select',
    },
    {
      division: '3. SI SUDHIR',
      beat: '06',
      area: 'SEC-5 BAWANA',
      staffNames: [
        'ASI PARDEEP 937/OND',
        'HC DEEPAK 1972/OND',
        'HC RAJESH 324/OND',
        'HC SUNIL 2122/OND',
        'HC AMIT 2125/OND',
        'CT SANDEEP 2528/OND',
        'CT MOHAN 2631/OND',
      ],
      callSign: 'NIA 16',
      patrolMorning: 'Select',
      patrolEvening: 'Select',
    },
    {
      // division: '4',
      beat: '07',
      area: 'SANNOTH & PH2 DSIIDC',
      staffNames: [
        'HC JITENDER 2603/OND',
        'HC DEEPAK 2193/OND',
        'HC SANDEEP 1980/OND',
        'HC PARDEEP 166/OND',
        'CT YAGYA DUTT 2599',
        'CT KAILASH 2239/OND',
        'CT MANOJ 2413/OND',
      ],
      callSign: 'NIA 17',
      patrolMorning: 'Select',
      patrolEvening: 'Select',
    },
    {
      division: '4. SI  VISHNU',
      beat: '08',
      area: 'BHORGARH',
      staffNames: [
        'ASI RAJENDER 779/OND',
        'ASI WAZIR 1023/OND',
        'HC SONU KUNDU 126/OND',
        'HC KARAMBIR 2078/OND',
        'CT MOHIT 2322/OND',
        'CT ASHWANI 2189/OND',
      ],
      callSign: 'NIA 18',
      patrolMorning: 'Select',
      patrolEvening: 'Select',
    },
    {
      // division: '5',
      beat: '09',
      area: 'DSIIDC NARELA',
      staffNames: [
        'HC SUDHIR 2276/OND',
        'HC JAIDEEP 428/OND',
        'HC AMIT NO. 2591/OND',
        'CT MOHIT 2001/OND',
        'CT SHAMSHER 2309/OND',
      ],
      callSign: 'NIA 19',
      patrolMorning: 'Select',
      patrolEvening: 'Slect',
    },
    {
      division: '5.SI DEEPAK LATHWAL',
      beat: '10',
      area: 'TIKRI KHURD',
      staffNames: [
        'ASI PARVEEN 104/OND',
        'HC PARVEEN 1110/OND',
        'HC JITENDER 2034/OND',
        'HC SUNIL 1941/OND',
        'HC ADITYA 2589/OND',
        'HC SHANKAR 211/OND',
      ],
      callSign: 'NIA 20',
      patrolMorning: 'Select',
      patrolEvening: 'Select',
    },
  ]);
  const [Picketdata, setPicketdata]=useState([
    {
      picket:'day',
      staffNames:['ASI BIJENDER 234/OND'],
      area:['B-2B-4 CHOWK',
        'G7&G8 MAIN CHOWK JJC BAWANA'
      ],
    },
    {
      picket:'night',
      staffNames:['ASI BIJENDER 234/OND'],
      area:['B-2B-4 CHOWK',
        'G7&G8 MAIN CHOWK JJC BAWANA + DHG'
      ],
    }
  ]);
 
const [tasksToSubmit, setTasksToSubmit] = useState([]);

useEffect(() => {
  
  
}, [tasksToSubmit]); 
const selectedNamePicket = async (name, time, type) => {
  if (name === "None") {
   
    return;
  }

  let startTime, endTime;

  try {
    const response = await axios.post(`${API_URL}/api/users/number`, { name });
    const { name: fetchedName, phoneNumber } = response.data;

    if (time === 'patrolDay') {
      startTime = '08:00:00';
      endTime = '20:00:00';
    } else {
      startTime = '20:00:00';
      endTime = '08:00:00';
    }

    const task = {
      name: fetchedName,
      phoneNumber: phoneNumber,
      isChecked: true,
      startTime: startTime,
      endTime: endTime,
      type: type,
    };

    setTasksToSubmit(prevTasks => [...prevTasks, task]);

  } catch (error) {
    console.error('Error assigning duty:', error.message);
  }
};

const selectedName = async (name, time, type) => {
  if (name === "None") {
   
    return;
  }

  let startTime, endTime;

  try {
    const response = await axios.post(`${API_URL}/api/users/number`, { name });
    const { name: fetchedName, phoneNumber } = response.data;

    if (time === 'patrolEvening') {
      startTime = '18:00:00';
      endTime = '23:00:00';
    } else {
      startTime = '09:00:00';
      endTime = '17:00:00';
    }

    const task = {
      name: fetchedName,
      phoneNumber: phoneNumber,
      isChecked: true,
      startTime: startTime,
      endTime: endTime,
      type: type,
    };

    setTasksToSubmit(prevTasks => [...prevTasks, task]);

  } catch (error) {
    console.error('Error assigning duty:', error.message);
  }
};


const formatDate = (date) => date.toISOString().split('T')[0];
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (tasksToSubmit.length > 0) {
      try {
        // Map through tasks and create an array of promises
        const promises = tasksToSubmit.map((task) => {
          const taskData = {
            name: task.name,
            phoneNumber: task.phoneNumber,
            date: formatDate(currentDate),
            isChecked: task.isChecked,
            startTime: task.startTime,
            endTime: task.endTime,
            type: task.type,
          };
  
          return axios.post(`${API_URL}/api/assignDuty`, taskData);
        });
  
        // Wait for all promises to resolve or reject
        const results = await Promise.allSettled(promises);
  
        // Check the results and handle success or failure for each task
        const allSuccess = results.every(result => result.status === 'fulfilled');
  
        if (allSuccess) {
          toast.success('All duties assigned successfully!', {
            position: 'top-center',
          });
        } else {
          toast.warn('Some duties could not be assigned. Please check the errors.', {
            position: 'top-center',
          });
        }
      } catch (error) {
        console.error('Error assigning duties:', error.response ? error.response.data : error.message);
        toast.error('Failed to assign duties. Please try again later.', {
          position: 'top-center',
        });
      }
    } else {
      toast.warn('Please fill start and end times for necessary tasks.');
    }
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRosterData(prevState => ({
      ...prevState,
      [name]: value,
    }));
    setDutyRosterData(prevState => ({
      ...prevState,
      [name]: value,
    }));
    setDutyContainer(prevState =>({
      ...prevState,
      [name]:value,
    }))
  };
  const handleTextInputChange = (e, rowIndex, field) => {
    const updatedData = [...data];
    updatedData[rowIndex][field] = e.target.value;
    setData(updatedData);
  };
  const handleSelectChange = (e, rowIndex, shift) => {
    const updatedData = [...data];
    if (shift === 'morning') {
      updatedData[rowIndex].patrolMorning = e.target.value;
    } else {
      updatedData[rowIndex].patrolEvening = e.target.value;
    }
    setData(updatedData);
  };


  const handleExportPDF = () => {
    const pdf = new jsPDF("p", "mm", "a4");

    pdf.html(componentRef.current, {
      callback: (doc) => {
        doc.save("DutyRoster.pdf");
      },
      x: 10,  // X-axis margin
      y: 10,  // Y-axis margin
      width: 180,  // Width of the content (within the page margins)
      windowWidth: 800,  // Width of the browser window (scaling factor)
    });
  };

  const handleExportDOC = () => {
    const htmlContent = componentRef.current.outerHTML;
  
    const updatedHtmlContent = htmlContent.replace(/<input[^>]*value="([^"]*)"/g, (match, value) => {
      return value ? value : '';
    });
  
    const docContent = `
      <html>
        <head>
          <style>
            body {
              font-family: Arial, sans-serif;
              background-color: #fff;
              color: #000;
              margin: 0;
              padding: 10px;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 10px;
            }
            table th, table td {
              border: 1px solid #000;
              padding: 8px 12px;
              text-align: left;
            }
            table th {
              background-color: #ccc;
            }
            table tr:nth-child(even) {
              background-color: #f2f2f2;
            }
            table tr:nth-child(odd) {
              background-color: #e6e6e6;
            }
          </style>
        </head>
        <body>
          <h2>Duty Roster</h2>
          <p><strong>DUTY ROSTER OF PS NIA STAFF</strong></p>
          <div>${updatedHtmlContent}</div>
        </body>
      </html>
    `;
  
    const blob = new Blob([docContent], { type: "application/msword" });
  
    saveAs(blob, "DutyRoster.doc");
  };


  return (
    <>   
      <div className="actions">
        <button onClick={handleSubmit}>Submit</button>
        <button onClick={handleExportPDF}>Export as PDF</button>
        <button onClick={handleExportDOC}>Export as DOC</button>
      </div>

      <div ref={componentRef}>
        <div className="common-container duty-roster">
          <img src={DELHI_POLICE_LOGO} alt="Delhi Police Logo" />
          
          <div className="header">
            <div className="top-header">
              <p className="left" style={{ fontWeight: 'bold' }}>DUTY ROSTER OF PS NIA STAFF</p>
              <p className="right">DATE {formattedDate}</p>
            </div>
            <div className="header-row">
              <p className="left">POLICE STATION........NIA</p>
              <p className="right">SUB-DIVISION NARELA DELHI</p>
            </div>
            <div className="header-row">
              <p className="left">DISTRICT...................OND</p>
            </div>
            <div className="header-row">
            <p className="left">
    SHO - <input className="table-input" type="text" 
      value={shoName} 
      onChange={(e) => setShoName(e.target.value)} 
      style={{ width: 'auto', display: 'inline', border: 'none', background: 'none' }}
    />
  </p>
            </div>
            <div className="header-row">
              <p className="left">INSPECTOR LAW & ORDER...</p>
            </div>
            <div className="header-row">
              <p className="left"><em>INSPECTOR INVESTIGATION...............INSP SANJAY KUMAR</em></p>
            </div>            
          </div>


          <table className="table-table roster-table">
  <thead>
    <tr>
      <th className="table-th"> Particulars</th>
      <th className="table-th"> Insp.</th>
      <th className="table-th"> SI</th>
      <th className="table-th"> ASI</th>
      <th className="table-th"> HC</th>
      <th className="table-th"> Ct.</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td className="table-td">Sanctioned Strength</td>
      <td className="table-td"><input className="table-input" type="text" name="sanctionedStrengthInsp" value={rosterData.sanctionedStrengthInsp} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="sanctionedStrengthSI" value={rosterData.sanctionedStrengthSI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="sanctionedStrengthASI" value={rosterData.sanctionedStrengthASI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="sanctionedStrengthHC" value={rosterData.sanctionedStrengthHC} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="sanctionedStrengthCt" value={rosterData.sanctionedStrengthCt} onChange={handleInputChange} /></td>
    </tr>
    <tr>
      <td className="table-td">Present Strength</td>
      <td className="table-td"><input className="table-input" type="text" name="presentStrengthInsp" value={rosterData.presentStrengthInsp} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="presentStrengthSI" value={rosterData.presentStrengthSI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="presentStrengthASI" value={rosterData.presentStrengthASI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="presentStrengthHC" value={rosterData.presentStrengthHC} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="presentStrengthCt" value={rosterData.presentStrengthCt} onChange={handleInputChange} /></td>
    </tr>
    <tr>
      <td className="table-td">Shortage</td>
      <td className="table-td"><input className="table-input" type="text" name="shortageInsp" value={rosterData.shortageInsp} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="shortageSI" value={rosterData.shortageSI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="shortageASI" value={rosterData.shortageASI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="shortageHC" value={rosterData.shortageHC} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="shortageCt" value={rosterData.shortageCt} onChange={handleInputChange} /></td>
    </tr>
    <tr>
      <td className="table-td">Casual Leave</td>
      <td className="table-td"><input className="table-input" type="text" name="casualLeaveInsp" value={rosterData.casualLeaveInsp} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="casualLeaveSI" value={rosterData.casualLeaveSI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="casualLeaveASI" value={rosterData.casualLeaveASI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="casualLeaveHC" value={rosterData.casualLeaveHC} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="casualLeaveCt" value={rosterData.casualLeaveCt} onChange={handleInputChange} /></td>
    </tr>
    <tr>
      <td className="table-td">Earned Leave/CCL</td>
      <td className="table-td"><input className="table-input" type="text" name="earnedLeaveInsp" value={rosterData.earnedLeaveInsp} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="earnedLeaveSI" value={rosterData.earnedLeaveSI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="earnedLeaveASI" value={rosterData.earnedLeaveASI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="earnedLeaveHC" value={rosterData.earnedLeaveHC} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="earnedLeaveCt" value={rosterData.earnedLeaveCt} onChange={handleInputChange} /></td>
    </tr>
    <tr>
      <td className="table-td">Absent</td>
      <td className="table-td"><input className="table-input" type="text" name="absentInsp" value={rosterData.absentInsp} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="absentSI" value={rosterData.absentSI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="absentASI" value={rosterData.absentASI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="absentHC" value={rosterData.absentHC} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="absentCt" value={rosterData.absentCt} onChange={handleInputChange} /></td>
    </tr>
    <tr>
      <td className="table-td">Medical Rest</td>
      <td className="table-td"><input className="table-input" type="text" name="medicalRestInsp" value={rosterData.medicalRestInsp} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="medicalRestSI" value={rosterData.medicalRestSI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="medicalRestASI" value={rosterData.medicalRestASI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="medicalRestHC" value={rosterData.medicalRestHC} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="medicalRestCt" value={rosterData.medicalRestCt} onChange={handleInputChange} /></td>
    </tr>
    <tr>
      <td className="table-td">Out Station</td>
      <td className="table-td"><input className="table-input" type="text" name="outStationInsp" value={rosterData.outStationInsp} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="outStationSI" value={rosterData.outStationSI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="outStationASI" value={rosterData.outStationASI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="outStationHC" value={rosterData.outStationHC} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="outStationCt" value={rosterData.outStationCt} onChange={handleInputChange} /></td>
    </tr>
    <tr>
      <td className="table-td">Arrangement</td>
      <td className="table-td"><input className="table-input" type="text" name="arrangementInsp" value={rosterData.arrangementInsp} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="arrangementSI" value={rosterData.arrangementSI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="arrangementASI" value={rosterData.arrangementASI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="arrangementHC" value={rosterData.arrangementHC} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="arrangementCt" value={rosterData.arrangementCt} onChange={handleInputChange} /></td>
    </tr>
    <tr>
      <td className="table-td">Attach Staff</td>
      <td className="table-td"><input className="table-input" type="text" name="attachStaffInsp" value={rosterData.attachStaffInsp} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="attachStaffSI" value={rosterData.attachStaffSI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="attachStaffASI" value={rosterData.attachStaffASI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="attachStaffHC" value={rosterData.attachStaffHC} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="attachStaffCt" value={rosterData.attachStaffCt} onChange={handleInputChange} /></td>
    </tr>
    <tr>
      <td className="table-td">Duty Present</td>
      <td className="table-td"><input className="table-input" type="text" name="dutyPresentInsp" value={rosterData.dutyPresentInsp} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="dutyPresentSI" value={rosterData.dutyPresentSI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="dutyPresentASI" value={rosterData.dutyPresentASI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="dutyPresentHC" value={rosterData.dutyPresentHC} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="dutyPresentCt" value={rosterData.dutyPresentCt} onChange={handleInputChange} /></td>
    </tr>
    <tr>
      <td className="table-td">Total</td>
      <td className="table-td"><input className="table-input" type="text" name="totalInsp" value={rosterData.totalInsp} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="totalSI" value={rosterData.totalSI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="totalASI" value={rosterData.totalASI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="totalHC" value={rosterData.totalHC} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="totalCt" value={rosterData.totalCt} onChange={handleInputChange} /></td>
    </tr>
    <tr>
      <td className="table-td">Present Home Guards</td>
      <td className="table-td"><input className="table-input" type="text" name="presentHomeGuards" value={rosterData.presentHomeGuards} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="presentHomeGuardsSI" value={rosterData.presentHomeGuardsSI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="presentHomeGuardsASI" value={rosterData.presentHomeGuardsASI} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="presentHomeGuardsHC" value={rosterData.presentHomeGuardsHC} onChange={handleInputChange} /></td>
      <td className="table-td"><input className="table-input" type="text" name="presentHomeGuardsCt" value={rosterData.presentHomeGuardsCt} onChange={handleInputChange} /></td>
    </tr>
  </tbody>
</table>

    </div>

    <div className="common-container administrative-duty">
      <h2 className="table-h2">ADMINISTRATIVE DUTY</h2>
      <table className="table-table duty-table">
        <thead>
          <tr>
            <th className="table-th"> SL</th>
            <th className="table-th " colSpan={2} style={{width:'20%'}}>NATURE OF DUTY</th>
            <th className="table-th " colSpan={2} style={{width:'25%'}}>8 AM TO 4 PM</th>
            <th className="table-th " colSpan={2} style={{width:'25%'}}>4 PM TO 12 PM</th>
            <th className="table-th " colSpan={2} style={{width:'25%'}}>12 PM TO 8 AM</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="table-td">1</td>
            <td className="table-td" colSpan={2} style={{width:'20%'}}> <strong>DUTY OFFICER</strong> </td>
            <td className="table-td" colSpan={2} style={{width:'25%'}}><input className="table-input" type="text" name="row1_8to4" value={dutyRosterData.row1_8to4} onChange={handleInputChange} /></td>
            <td className="table-td" colSpan={2} style={{width:'25%'}}><input className="table-input" type="text" name="row1_4to12" value={dutyRosterData.row1_4to12} onChange={handleInputChange} /></td>
            <td className="table-td" colSpan={2} style={{width:'25%'}}><input className="table-input" type="text" name="row1_12to8" value={dutyRosterData.row1_12to8} onChange={handleInputChange} /></td>
          </tr>
          <tr>
            <td className="table-td">2</td>
            <td className="table-td" colSpan={2} style={{width:'20%'}}> <strong>D.D. WRITER</strong> </td>
            <td className="table-td" colSpan={2} style={{width:'25%'}}><input className="table-input" type="text" name="row2_8to4" value={dutyRosterData.row2_8to4} onChange={handleInputChange} /></td>
<td className="table-td" colSpan={2} style={{width:'25%'}}><input className="table-input" type="text" name="row2_4to12" value={dutyRosterData.row2_4to12} onChange={handleInputChange} /></td>
<td className="table-td" colSpan={2} style={{width:'25%'}}><input className="table-input" type="text" name="row2_12to8" value={dutyRosterData.row2_12to8} onChange={handleInputChange} /></td>
          </tr>
          <tr>
            <td className="table-td">3</td>
            <td className="table-td" colSpan={2} style={{width:'20%'}}> <strong>SANTRY DUTY</strong> </td>
            <td className="table-td" colSpan={4} style={{width:'50%'}}><textarea className="styled-textarea" rows={2} name="row3_8to4" value={dutyRosterData.row3_8to4} onChange={handleInputChange} /></td>
{/* <td className="table-td"><input className="table-input" type="text" name="row3_4to12" value={dutyRosterData.row3_4to12} onChange={handleInputChange} /></td> */}
<td className="table-td" colSpan={2} style={{width:'25%'}}><textarea className="styled-textarea" rows={2} name="row3_12to8" value={dutyRosterData.row3_12to8} onChange={handleInputChange} /></td>
          </tr>
          <tr>
            <td className="table-td">4</td>
            <td className="table-td" colSpan={2} style={{width:'20%'}}> <strong>READER/DAK DUTY</strong> </td>
            <td className="table-td" colSpan={2} style={{width:'25%'}}><input className="table-input" type="text" name="row4_8to4" value={dutyRosterData.row4_8to4} onChange={handleInputChange} /></td>
<td className="table-td" colSpan={2} style={{width:'25%'}}><input className="table-input" type="text" name="row4_4to12" value={dutyRosterData.row4_4to12} onChange={handleInputChange} /></td>
<td className="table-td" colSpan={2} style={{width:'25%'}}><input className="table-input" type="text" name="row4_12to8" value={dutyRosterData.row4_12to8} onChange={handleInputChange} /></td>
          </tr>
          <tr>
            <td className="table-td">5</td>
            <td className="table-td" colSpan={2} style={{width:'20%'}}> <strong>DEPLOYMENT OFF.</strong> </td>
            <td className="table-td" colSpan={2} style={{width:'25%'}}><input className="table-input" type="text" name="row5_8to4" value={dutyRosterData.row5_8to4} onChange={handleInputChange} /></td>
<td className="table-td" colSpan={2} style={{width:'25%'}}><input className="table-input" type="text" name="row5_4to12" value={dutyRosterData.row5_4to12} onChange={handleInputChange} /></td>
<td className="table-td" colSpan={2} style={{width:'25%'}}><textarea className="styled-textarea" rows={2} name="row5_12to8" value={dutyRosterData.row5_12to8} onChange={handleInputChange} /></td>
          </tr>
          <tr>
            <td className="table-td">6</td>
            <td className="table-td" colSpan={2} style={{width:'20%'}}>  <strong>RECORD</strong></td>
            <td className="table-td" colSpan={6} style={{width:'75%'}}><textarea className="styled-textarea" rows={2}  name="row6_8to4" value={dutyRosterData.row6_8to4} onChange={handleInputChange} /></td>

          </tr>
          <tr>
  <td className="table-td">7</td>
  <td className="table-td" colSpan={2} style={{ width: '20%' }}> <strong>MALKHANA (GP)</strong> </td>
  <td className="table-td" colSpan={6} style={{ width: '75%' }}>
    <textarea className="styled-textarea" rows={2} name="row7_8to4" value={dutyRosterData.row7_8to4} onChange={handleInputChange} />
  </td>
</tr>
<tr>
  <td className="table-td">8</td>
  <td className="table-td" colSpan={2} style={{ width: '20%' }}> <strong>MALKHANA (CP)</strong> </td>
  <td className="table-td" colSpan={6} style={{ width: '75%' }}>
    <textarea className="styled-textarea" rows={2} name="row8_8to4" value={dutyRosterData.row8_8to4} onChange={handleInputChange} />
  </td>
</tr>
<tr>
  <td className="table-td">9</td>
  <td className="table-td" colSpan={2} style={{ width: '20%' }}> <strong>COLLATOR</strong> </td>
  <td className="table-td" colSpan={6} style={{ width: '75%' }}>
    <textarea className="styled-textarea" rows={2} name="row9_8to4" value={dutyRosterData.row9_8to4} onChange={handleInputChange} />
  </td>
</tr>
<tr>
  <td className="table-td">10</td>
  <td className="table-td" colSpan={2} style={{ width: '20%' }}> <strong>Pairvi Officer</strong> </td>
  <td className="table-td" colSpan={6} style={{ width: '75%' }}>
    <textarea className="styled-textarea" rows={2} name="row10_8to4" value={dutyRosterData.row10_8to4} onChange={handleInputChange} />
  </td>
</tr>
<tr>
  <td className="table-td">11</td>
  <td className="table-td" colSpan={2} style={{ width: '20%' }}> <strong>CCTNS STAFF</strong> </td>
  <td className="table-td" colSpan={6} style={{ width: '75%' }}>
    <textarea className="styled-textarea" rows={2} name="row11_8to4" value={dutyRosterData.row11_8to4} onChange={handleInputChange} />
  </td>
</tr>

          <tr>
            <td className="table-td">12</td>
            <td className="table-td" colSpan={2} style={{width:'20%'}}> <strong>WHD</strong> </td>
            <td className="table-td" colSpan={2} style={{ width: '25%' }}><textarea className="styled-textarea" rows={2}  name="row12_8to4" value={dutyRosterData.row12_8to4} onChange={handleInputChange} /></td>
<td className="table-td" colSpan={4} style={{ width: '50%' }}><textarea className="styled-textarea" rows={2} name="row12_4to12" value={dutyRosterData.row12_4to12} onChange={handleInputChange} /></td>

          </tr>
          <tr>
            <td className="table-td">13</td>
            <td className="table-td" colSpan={2} style={{width:'20%'}}> <strong>P.F. DESK</strong> </td>
            <td className="table-td" colSpan={2} style={{width:'25%'}}><textarea className="styled-textarea" rows={2}  name="row13_8to4" value={dutyRosterData.row13_8to4} onChange={handleInputChange} /></td>
<td className="table-td" colSpan={2} style={{width:'25%'}}><textarea className="styled-textarea" rows={2}  name="row13_4to12" value={dutyRosterData.row13_4to12} onChange={handleInputChange} /></td>
<td className="table-td" colSpan={2} style={{width:'25%'}}><textarea className="styled-textarea" rows={2}  name="row13_12to8" value={dutyRosterData.row13_12to8} onChange={handleInputChange} /></td>
          </tr>
          <tr>
            <td className="table-td">14</td>
            <td className="table-td" colSpan={2} style={{width:'20%'}}> <strong>OPERATOR TO</strong> </td>
            <td className="table-td" colSpan={6} style={{ width: '75%' }}><textarea className="styled-textarea" rows={2}  name="row14_8to4" value={dutyRosterData.row14_8to4} onChange={handleInputChange} /></td>


          </tr>
          <tr>
            <td className="table-td">15</td>
            <td className="table-td" colSpan={2} style={{width:'20%'}}> <strong>DVR. TO SHO</strong> </td>
            <td className="table-td" colSpan={6} style={{ width: '75%' }}><textarea className="styled-textarea" rows={2} name="row15_8to4" value={dutyRosterData.row15_8to4} onChange={handleInputChange} /></td>

          </tr>
          <tr>
            <td className="table-td" rowSpan={2}>16</td>
            <td className="table-td" colSpan={2} style={{width:'20%'}}> <strong>CASUAL LEAVE</strong> </td>
            <td className="table-td" colSpan={6} style={{ width: '75%' }}><textarea className="styled-textarea" rows={3}  name="row16_8to4" value={dutyRosterData.row16_8to4} onChange={handleInputChange} /></td>

          </tr>
          <tr>
          <td className="table-td" colSpan={2} style={{width:'20%'}}> <strong>EL & CCL</strong> </td>
          <td className="table-td" colSpan={6} style={{ width: '75%' }}><textarea className="styled-textarea" rows={2}  name="row16_8to4" value={dutyRosterData.row16_4to12} onChange={handleInputChange} /></td>
          </tr>
          <tr>
            <td className="table-td">17</td>
            <td className="table-td" colSpan={2} style={{width:'20%'}}> <strong>Absent</strong> </td>
            <td className="table-td" colSpan={6} style={{ width: '75%' }}><input className="table-input" type="text" name="row17_8to4" value={dutyRosterData.row17_8to4} onChange={handleInputChange} /></td>

          </tr>
          <tr>
            <td className="table-td">18</td>
            <td className="table-td" colSpan={2} style={{width:'20%'}}> <strong>Duty Rest</strong> </td>
            <td className="table-td" colSpan={6} style={{ width: '75%' }}><textarea className="styled-textarea" rows={2} name="row18_8to4" value={dutyRosterData.row18_8to4} onChange={handleInputChange} /></td>

          </tr>
          <tr>
            <td className="table-td">19</td>
            <td className="table-td" colSpan={2} style={{width:'20%'}}> <strong>Medical Rest</strong> </td>
            <td className="table-td" colSpan={6} style={{ width: '75%' }}><textarea className="styled-textarea" rows={2} name="row19_8to4" value={dutyRosterData.row19_8to4} onChange={handleInputChange} /></td>

          </tr>
          <tr>
            <td className="table-td">20</td>
            <td className="table-td" colSpan={2} style={{width:'20%'}}> <strong>Out Station</strong> </td>
            <td className="table-td" colSpan={6} style={{ width: '75%' }}><textarea className="styled-textarea" rows={2}  name="row20_8to4" value={dutyRosterData.row20_8to4} onChange={handleInputChange} /></td>

          </tr>
        </tbody>
      </table>
    </div>

    <div className="common-container duty-container">
  <table className="table-table duty-table">
    <thead>
      <tr>
        <th className="table-th " colSpan="2">PP METRO VIHAR STAFF</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className="table-td">
          <textarea
            name="ppmetro_col1"
            value={dutyContainer.ppmetro_col1}
            onChange={handleInputChange}
            className="styled-textarea"
            rows={6}
            cols={30}
          />
        </td>
        <td className="table-td">
          <textarea
            name="ppmetro_col2"
            value={dutyContainer.ppmetro_col2}
            onChange={handleInputChange}
            className="styled-textarea"
            rows={6}
            cols={30}
          />
        </td>
      </tr>
      <tr>
        <th className="table-th " colSpan="2">LADIES STAFF</th>
      </tr>
      <tr>
        <td className="table-td">
          <textarea
            name="ladiesStaff_col1"
            value={dutyContainer.ladiesStaff_col1}
            onChange={handleInputChange}
            className="styled-textarea"
            rows={8}
            cols={30}
          />
        </td>
        <td className="table-td">
          <textarea
            name="ladiesStaff_col2"
            value={dutyContainer.ladiesStaff_col2}
            onChange={handleInputChange}
            className="styled-textarea"
            rows={8}
            cols={30}
          />
        </td>
      </tr>
      <tr>
        <th className="table-th " colSpan="2">EMERGENCY DUTY</th>
      </tr>
      <tr>
        <td className="table-td">
          <textarea
            name="emegencyStaff_col1"
            value={dutyContainer.emegencyStaff_col1}
            onChange={handleInputChange}
            className="styled-textarea"
            rows={13}
            cols={30}
          />
        </td>
        <td className="table-td">
          <textarea
            name="emegencyStaff_col2"
            value={dutyContainer.emegencyStaff_col2}
            onChange={handleInputChange}
            className="styled-textarea"
            rows={13}
            cols={30}
          />
        </td>
      </tr>
    </tbody>
  </table>
</div>


    <div className="common-container duty-container">
  <h2 className="table-h2">LAW & ORDER AND GENERAL DUTY</h2>
  <table className="table-table duty-table">
    <thead>
      <tr>
        <th className="table-th thin-column1">Division</th>
        <th className="table-th thin-column2">BEAT NO</th>
        <th className="table-th thick-column1">Area</th>
        <th className="table-th thick-column2">Name of Staff</th>
        <th className="table-th thin-column3">Call Sign</th>
        <th className="table-th thin-column">M/C Patrolling Morning</th>
        <th className="table-th thin-column">Evening M/C Patrolling</th>
      </tr>
    </thead>
    <tbody>
    {data.map((row, index) => {
  const isHighlighted = beatsForCurrentDay.includes(row.beat); // Check if beat is in the list of highlighted beats
  return (
    <tr key={index} style={{ backgroundColor: isHighlighted ? 'pink' : 'transparent' }}>
      <td className="table-td">
        <textarea
          style={{ fontWeight: "bold" }}
          className="styled-textarea"
          value={row.division}
          rows="6"  // Adjusting height for readability
          onChange={(e) => handleTextInputChange(e, index, 'division')}
        />
      </td>
      <td className="table-td">
        <input className="table-input" type="text" style={{ fontWeight: "bold" }}
          value={row.beat}
          onChange={(e) => handleTextInputChange(e, index, 'beat')}
        />
      </td>
      <td className="table-td">
        <textarea
          style={{ fontWeight: "bold" }}
          className="styled-textarea"
          value={row.area}
          rows="4"  // Adjusting height for readability
          onChange={(e) => handleTextInputChange(e, index, 'area')}
        />
      </td>
      <td className="table-td">
        <textarea
          className="styled-textarea"
          value={row.staffNames.join('\n')}
          rows="8"  // Adjusting the height for readability
          onChange={(e) => {
            const updatedData = [...data];
            updatedData[index].staffNames = e.target.value.split('\n');
            setData(updatedData);
          }}
        />
      </td>
      <td className="table-td">
        <input className="table-input" type="text"
          value={row.callSign}
          onChange={(e) => handleTextInputChange(e, index, 'callSign')}
        />
      </td>
      <td className="table-td">
        <select
          value={row.patrolMorning}
          onChange={(e) => {
            const selectedNameValue = e.target.value;
            handleSelectChange(e, index, 'morning');
            selectedName(selectedNameValue, 'patrolMorning', 'beat');
          }}
        >
          <option className="table-option" value="None">None</option>
          {row.staffNames.map((name, i) => (
            <option className="table-option" key={i} value={name}>
              {name}
            </option>
          ))}
        </select>
      </td>
      <td className="table-td">
        <select
          value={row.patrolEvening}
          onChange={(e) => {
            const selectedNameValue = e.target.value;
            handleSelectChange(e, index, 'evening');
            selectedName(selectedNameValue, 'patrolEvening', 'beat');
          }}
        >
          <option className="table-option" value="None">None</option>
          {row.staffNames.map((name, i) => (
            <option className="table-option" key={i} value={name}>
              {name}
            </option>
          ))}
        </select>
      </td>
    </tr>
  );
})}

    </tbody>
  </table>
</div>



    {/* last page */}
    <div className="duty-container">
  <table className="table-table duty-table">
    <thead>
      <tr>
        <th className="table-th " colSpan="1" style={{width:"5%"}}>Sl No</th>
        <th className="table-th " colSpan="1" style={{width:"15%"}}>Head</th>
        <th className="table-th " colSpan="4" style={{width:"35%"}}>Name</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className="table-td" colSpan="1" style={{width:"5%"}}><input className="table-input" type="text" defaultValue="1" /></td>
        <td className="table-td" colSpan="1" style={{width:"15%"}}><input className="table-input" type="text" defaultValue="J FORCE" /></td>
        <td className="table-td" colSpan="4" style={{width:"35%"}}><input className="table-input" type="text" defaultValue="ASI AMRIT, CT. SANDEEP 2528/OND" /></td>
      </tr>
      <tr>
        <td className="table-td"><input className="table-input" type="text" defaultValue="2" /></td>
        <td className="table-td" colSpan="1" style={{width:"10%"}}><input className="table-input" type="text" defaultValue="J FORCE" /></td>
        <td className="table-td" colSpan="4" style={{width:"35%"}}>
          <input className="table-input" type="text" defaultValue="HC AJAY 2209/OND, HC AMIT 2611/OND, HC JITENDER 2603/OND" />
        </td>
      </tr>
      <tr>
        <td className="table-td"><input className="table-input" type="text" defaultValue="3" /></td>
        <td className="table-td" colSpan="1" style={{width:"10%"}}><input className="table-input" type="text" defaultValue="PSO Duty" /></td>
        <td className="table-td" colSpan="4" style={{width:"35%"}}>
          <textarea defaultValue="CT. NARENDER 2106/OND - ASI BALKISHAN, CT DINESH 2384/OND (PSO 8AM TO 8PM) CT AAYUSH 2033/OND (8AM TO 8PM RESIDENCE), CT JITENDER 2408/OND (8PM TO 8AM RESIDENCE)" className="styled-textarea" rows="3" />
        </td>
      </tr>
      <tr>
        <td className="table-td"><input className="table-input" type="text" defaultValue="4" /></td>
        <td className="table-td" colSpan="1" style={{width:"10%"}}><input className="table-input" type="text" defaultValue="PO STAFF" /></td>
        <td className="table-td" colSpan="4" style={{width:"35%"}}><input className="table-input" type="text" defaultValue="SI RAJESH D-1406, HC SANDEEP 326/OND, HC KIRSHAN 226/OND" /></td>
      </tr>
      <tr>
        <td className="table-td"><input className="table-input" type="text" defaultValue="5" /></td>
        <td className="table-td" colSpan="1" style={{width:"10%", fontWeight:"bolder"}}><input className="table-input" type="text" defaultValue="ELECTION DUTY" /></td>
        <td className="table-td" colSpan="4" style={{width:"35%"}}><input className="table-input" type="text" defaultValue="HC RAJESH 2470/OND" /></td>
      </tr>
      <tr>
        <td className="table-td"><input className="table-input" type="text" defaultValue="6" /></td>
        <td className="table-td" colSpan="1" style={{width:"10%"}}><input className="table-input" type="text" defaultValue="ARRANGEMENT" /></td>
        <td className="table-td" colSpan="4" style={{width:"35%"}}>
          <textarea defaultValue="ASI CHAND RAM 153/OND, HC RAVINDER 2468/OND, HC PARDEEP 285/OND, CT. MANOJ 2413/OND, WCT. JYOTI 1569/OND, CT. RAMESH 2068/OND" className="styled-textarea" rows="3"/>
        </td>
      </tr>
      <tr>
        <td className="table-td"><input className="table-input" type="text" defaultValue="7" /></td>
        <td className="table-td" colSpan="1" style={{width:"10%"}}><input className="table-input" type="text" defaultValue="ANTI B.TEAM & CRACK TEAM" /></td>
        <td className="table-td" colSpan="4" style={{width:"35%"}}>
          <textarea defaultValue="HC AMIT 2125/OND, HC DEEPAK RANA 1972/OND, HC JITENDRA 2034/OND, CT. MOHAN 2631/OND" className="styled-textarea" rows="2"/>
        </td>
      </tr>
      <tr>
  <td className="table-td"><input className="table-input" type="text" defaultValue="8" /></td>
  <td className="table-td" colSpan="1" style={{width:"10%"}}><input className="table-input" type="text" defaultValue="ATTACHED STAFF" /></td>
  <td className="table-td" colSpan="4" style={{width:"35%"}}>
    <textarea
      defaultValue="ASI ABHINAV 141/OND (S.B DAIRY), SI STPAL 4974-D (S.B DAIRY), HC DEVENDER 198/OND (S.B DAIRY)"
      className="styled-textarea"
      rows="2"
    />
  </td>
</tr>
<tr>
  <td className="table-td"><input className="table-input" type="text" defaultValue="9" /></td>
  <td className="table-td" colSpan="1" style={{width:"10%"}}><input className="table-input" type="text" defaultValue="ERV" /></td>
  <td className="table-td" colSpan="2" style={{width:"17.5%"}}>
   
       <textarea className="styled-textarea"
       defaultValue="SI ASHOK 692/OND, HC PARVEEN 2564/OND"
       rows="2"></textarea>
        
        </td><td className="table-td" colSpan="2" style={{width:"17.5%"}}>
      <textarea className="styled-textarea"
       defaultValue="ASI RAKESH 734/OND, HC AJAY 945/OND"
       rows="2"></textarea>
       
      
  </td>
 
</tr>
<tr>
  <td className="table-td"><input className="table-input" type="text" defaultValue="" /></td>
  <td className="table-td" colSpan="1" style={{width:"10%"}}><input className="table-input" type="text" defaultValue="" /></td>
  <td className="table-td" colSpan="2" style={{width:"17.5%"}}>
    
        <input className="table-input" type="text"
          defaultValue="8AM TO 8 PM"
          style={{ width: "100%", textAlign:"center", fontWeight:"bold" }}
        />
        </td><td className="table-td" colSpan="2" style={{width:"17.5%"}}>
     
     
        <input className="table-input" type="text"
          defaultValue="8PM TO 8AM"
          style={{ width: "100%", textAlign:"center", fontWeight:"bold" }}
        />
    
  </td>
  </tr>
<tr>
  <td className="table-td"><input className="table-input" type="text" defaultValue="10" /></td>
  <td className="table-td" colSpan="1" style={{width:"10%"}}><input className="table-input" type="text" defaultValue="DAY PICKET" /></td>
  <td className="table-td" colSpan="2" style={{width:"17.5%"}}>
        <input className="table-input" type="text"
          defaultValue="B-2B-4 CHOWK"
          style={{ width: "100%" }}
        />
        <select
          defaultValue="None"
          onChange={(e) => selectedNamePicket(e.target.value, "patrolDay", "picket")}
          className="picketSelect"
          style={{ width: "100%" }}
        >
          <option className="table-option"  value="None">None</option>
          {Picketdata[0].staffNames.map((name, index) => (
            <option className="table-option"  key={index} value={name}>
              {name}
            </option>
          ))}
        </select>
        </td><td className="table-td" colSpan="2" style={{width:"17.5%"}}>
        <input className="table-input" type="text"
          defaultValue="B-2B-4 CHOWK JJC BAWANA"
          style={{ width: "100%" }}
        />
        <select
          defaultValue="None"
          onChange={(e) => selectedNamePicket(e.target.value, "patrolNight", "picket")}
          className="picketSelect"
          style={{ width: "100%" }}
        >
          <option className="table-option"  value="None">None</option>
          {Picketdata[0].staffNames.map((name, index) => (
            <option className="table-option"  key={index} value={name}>
              {name}
            </option>
          ))}
        </select>
     
  </td>
</tr>
<tr>
  <td className="table-td"><input className="table-input" type="text" defaultValue="11" /></td>
  <td className="table-td" colSpan="1" style={{width:"10%"}}><input className="table-input" type="text" defaultValue="NIGHT PICKET DUTY" /></td>
  <td className="table-td" colSpan="2" style={{width:"17.5%"}}>
   
        <input className="table-input" type="text"
          defaultValue="B-2B-4 CHOWK"
          style={{ width: "100%" }}
        />
        <select
          defaultValue="None"
          onChange={(e) => selectedNamePicket(e.target.value, "patrolDay", "picket")}
          className="picketSelect"
          style={{ width: "100%" }}
        >
          <option className="table-option"  value="None">None</option>
          {Picketdata[1].staffNames.map((name, index) => (
            <option className="table-option"  key={index} value={name}>
              {name}
            </option>
          ))}
        </select>
        </td><td className="table-td" colSpan="2" style={{width:"17.5%"}}>
        <input className="table-input" type="text"
          defaultValue="B-2B-4 CHOWK JJC BAWANA + DHG"
          style={{ width: "100%" }}
        />
        <select
          defaultValue="None"
          onChange={(e) => selectedNamePicket(e.target.value, "patrolNight", "picket")}
          className="picketSelect"
          style={{ width: "100%" }}
        >
          <option className="table-option"  value="None">None</option>
          {Picketdata[1].staffNames.map((name, index) => (
            <option className="table-option"  key={index} value={name}>
              {name}
            </option>
          ))}
        </select>
    
  </td>
</tr>



    </tbody>
  </table>
  <div className="signatures">
        <p>
          Signature of Insp. Investigation __________ Signature of SHO
          __________
        </p>
      </div>
</div>

    
      </div>
    </>
  );
};

export default DutyRosterForm;
