import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import OuterNorthDistrictMap from "../../maps_google/outerNorthDistrictMap";
import Sidebar from "../../maps_google/sidebar/sidebar";
import Header from "../../Header/header";
import { API_URL } from "../../config/config";
import Leaderboard from "./LeaderBoard"; // Import the Leaderboard component
import data from './District_wise.json'

function Staff_tracking() {
  const location = useLocation();
  const [staffData, setStaffData] = useState([]);

  useEffect(() => {
    // Fetch live location data only when on the "Constable Location" page
    if (location.pathname === "/map/constable") {
      fetchActiveUsers();
    }
  }, [location.pathname]);

  // Fetch active users from the /api/active-users endpoint
  const fetchActiveUsers = async () => {
    try {
      const response = await fetch(`${API_URL}/api/active-users`);

      if (!response.ok) {
        throw new Error("Failed to fetch active users.");
      }

      const activeUsers = await response.json();

      const liveLocations = await Promise.all(
        activeUsers.map(async (user) => {
          try {
            const locationResponse = await fetch(`${API_URL}/users-location`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ phoneNumber: user.mobileNumber }),
            });

            if (!locationResponse.ok) {
              throw new Error(`Failed to fetch location for ${user.name}`);
            }

            const responseText = await locationResponse.text();
            if (!responseText) {
              console.warn(`No location data returned for user ${user.name}`);
              return null;
            }

            const locationData = JSON.parse(responseText);
            return {
              name: user.name,
              phone: user.mobileNumber,
              area: user.areas[0],
              startTime: user.dutyStartTime,
              endTime: user.dutyEndTime,
              lat: locationData.latitude,
              lng: locationData.longitude,
              type:user.taskType,
            };
          } catch (error) {
            console.error(
              `Error fetching location for user ${user.name}:`,
              error
            );
            return null;
          }
        })
      );

      setStaffData(liveLocations.filter((location) => location !== null));
    } catch (error) {
      console.error("Error fetching active users or location data:", error);
    }
  };
  return (
    <>
      <Header />
      <div style={{ display: "flex", flexDirection: "row", height: "100vh" }}>
        <Sidebar />
        <div style={{ flex: 3, padding: "10px", }}>
         <OuterNorthDistrictMap staffData={staffData} districtData={data} />

        </div>
        <div
          style={{
            flex: 1,
            padding: "10px",
            borderLeft: "1px solid #ddd",
            backgroundColor: "#f9f9f9",
            overflowY: "auto",
          }}
        >
          <Leaderboard />
        </div>
      </div>
    </>
  );
}

export default Staff_tracking;
