import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPS, setBeat, setCrime } from '../../../state/polygonSlice'
import crimeDataMay from '../../Tracking_feature/crime_tracking_FIR/crime_data_may.json';
import './crimePerBeatGraph.css'

const CrimeLeaderboard = ({ beat_no, PS }) => {
  const allCrimeData = React.useMemo(
    () => [ ...crimeDataMay],
    [ crimeDataMay]
  );

  const dispatch = useDispatch();

  const [leaderboardData, setLeaderboardData] = useState([]);

  useEffect(() => {
    const beatCrime = [
      { label: 'Dacoity', no_of_crime: 0 },
      { label: 'Murder', no_of_crime: 0 },
      { label: 'Att. To Murder', no_of_crime: 0 },
      { label: 'Robbery', no_of_crime: 0 },
      { label: 'Riot', no_of_crime: 0 },
      { label: 'Rape', no_of_crime: 0 },
      { label: 'Snatching', no_of_crime: 0 },
      { label: 'others', no_of_crime: 0 },
    ];

    const updatedCrimeData = [...beatCrime];

    for (const crime of allCrimeData) {
      if (crime.PS === PS && crime.BEAT == beat_no) {
        const crimeIndex = updatedCrimeData.findIndex(
          (item) => item.label === crime.TYPE
        );
        if (crimeIndex !== -1) {
          updatedCrimeData[crimeIndex].no_of_crime += 1;
        } else {
          // Add to "others"
          const othersIndex = updatedCrimeData.findIndex(
            (item) => item.label.toLowerCase() === 'others'
          );
          if (othersIndex !== -1) {
            updatedCrimeData[othersIndex].no_of_crime += 1;
          }
        }
      }
    }

    const sortedData = updatedCrimeData.sort((a, b) => {
      if (a.label.toLowerCase() === 'others') return 1; // Move "others" to the bottom
      if (b.label.toLowerCase() === 'others') return -1; // Keep "others" at the bottom
      return b.no_of_crime - a.no_of_crime; // Sort by no_of_crime in descending order
    });    

    setLeaderboardData(sortedData);
  }, [beat_no, allCrimeData]);

  const handleRowClick = (crime) => {
    dispatch(setPS(PS));
    dispatch(setBeat(beat_no));
    dispatch(setCrime(crime.label));
  };

  if (!leaderboardData.length) {
    return <p>Loading...</p>;
  }

  return (
    <div className="crime-leaderboard">
      <h3 className="leaderboard-title-h3">PS: {PS}</h3>
      <h2 className="leaderboard-title">Crime (Beat {beat_no})</h2>
      <table className="leaderboard-table">
        <thead>
          <tr className="leaderboard-header">
            <th className="leaderboard-column">Crime</th>
            <th className="leaderboard-column leaderboard-right">Number of Crimes</th>
          </tr>
        </thead>
        <tbody>
          {leaderboardData.map((crime, index) => (
            <tr
              key={index}
              className={`leaderboard-row ${index === 0 ? 'leaderboard-highlight' : ''}`}
              onClick={() => handleRowClick(crime)}
            >
              <td className="leaderboard-cell">{crime.label}</td>
              <td className="leaderboard-cell leaderboard-right">{crime.no_of_crime}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CrimeLeaderboard;
