import React, { useEffect, useState } from "react";
import { API_URL } from "../../config/config";

function Leaderboard() {
  const [leaderboardData, setLeaderboardData] = useState([]);

  useEffect(() => {
    fetchLeaderboardData();
  }, []);

  const fetchLeaderboardData = async () => {
    try {
      const response = await fetch(`${API_URL}/api/leaderboard`);

      if (!response.ok) {
        throw new Error("Failed to fetch leaderboard data.");
      }

      const data = await response.json();
      
      if (data.success && Array.isArray(data.data)) {
        setLeaderboardData(data.data);
      } else {
        console.error("Leaderboard data is not in the expected format:", data);
      }
    } catch (error) {
      console.error("Error fetching leaderboard data:", error);
    }
  };

  return (
    <div style={containerStyle}>
      <h3 style={headingStyle}>🏆 Leaderboard</h3>
      <div style={leaderboardContainerStyle}>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Rank</th>
              <th style={tableHeaderStyle}>Name</th>
              <th style={tableHeaderStyle}>Steps</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(leaderboardData) && leaderboardData.length > 0 ? (
              leaderboardData.map((item, index) => (
                <tr
                  key={item._id || index}
                  style={index % 2 === 0 ? evenRowStyle : oddRowStyle}
                >
                  <td style={tableCellStyle}>{index + 1}</td>
                  <td style={tableCellStyle}>{item.userId.name}</td>
                  <td style={tableCellStyle}>{item.steps}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" style={tableCellStyle}>
                  No leaderboard data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

// Styling for leaderboard container and table
const containerStyle = {
  position: "absolute",
  top: "17%",
  right: "2%",
  width: "24%",
  padding: "15px",
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  borderRadius: "16px",
  boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
  backdropFilter: "blur(10px)",
  margin: "0",
  maxHeight: "400px", // Set a maximum height for the leaderboard container
  overflow: "hidden", // Ensure the content doesn't overflow outside the container
};

// Leaderboard heading style
const headingStyle = {
  fontSize: "24px",
  fontWeight: "bold",
  color: "#ff6347",
  textAlign: "center",
  marginBottom: "15px",
};

// Style for the scrollable leaderboard content
const leaderboardContainerStyle = {
  maxHeight: "300px", // Restrict the height for scrolling
  overflowY: "auto", // Enables vertical scrolling
};

// Table styling
const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
  borderRadius: "8px",
  overflow: "hidden",
};

// Header row styling
const tableHeaderStyle = {
  backgroundColor: "#4CAF50",
  color: "white",
  padding: "8px",
  textAlign: "left",
  fontWeight: "bold",
  borderBottom: "2px solid #ddd",
  fontSize: "14px",
};

// Table cell styling
const tableCellStyle = {
  padding: "8px",
  textAlign: "left",
  borderBottom: "1px solid #ddd",
  color: "#333",
  fontSize: "14px",
};

// Even row styling with gradient
const evenRowStyle = {
  background: "linear-gradient(90deg, #a8ff78, #78ffd6)",
};

// Odd row styling with gradient
const oddRowStyle = {
  background: "linear-gradient(90deg, #ff9a9e, #fad0c4)",
};

export default Leaderboard;
