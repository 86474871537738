import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import crimeDataMay from './crime_data_may.json';

const CrimechartperPS = () => {
    const allCrimeData = [ ...crimeDataMay];

    const [CrimeDataPerPS, setCrimeDataPerPs] = useState([
        { label: "Alipur", no_of_crime: 0, bgcolor: "rgba(192,64,0,0.7)" },
        { label: "Bawana", no_of_crime: 0, bgcolor: "rgba(193,28,132,0.7)" },
        { label: "Bhalswa Dairy", no_of_crime: 0, bgcolor: "rgba(193,28,132,0.7)" },
        { label: "Narela", no_of_crime: 0, bgcolor: "rgba(173,255,47,0.7)" },
        { label: "NIA", no_of_crime: 0, bgcolor: "rgba(0,0,255,0.7)" },
        { label: "Samaypur Badli", no_of_crime: 0, bgcolor: "rgba(173,255,47,0.7)" },
        { label: "Shahbad Dairy", no_of_crime: 0, bgcolor: "rgba(255,255,0,0.7)" },
        { label: "Swaroop Nagar", no_of_crime: 0, bgcolor: "rgba(0,128,0,0.7)" }
      ]);

    const psCrimeData = {
        labels: CrimeDataPerPS.map((data) => data.label),
        datasets: [{
          label : 'E FIR (Property and MV Theft)',
          data : CrimeDataPerPS.map((data) => data.no_of_crime),
          backgroundColor : CrimeDataPerPS.map((data) => data.bgcolor),
        }]
    }

    const options = {
      responsive: true,
      animations: {
        tension: {
          duration: 2000,
          easing: "easeOutBounce",
        },
      },
      scales: {
        x: {
          ticks: {
            font: {
              size: 12,
              weight: "bold",
            },
            color: "#000",
          }
        },
        y: {
          ticks: {
            font: {
              size: 12,
              weight: "bold",
            },
            color: "#000",
          },
          beginAtZero: true,
        },
      },
    };

    useEffect(()=>{
        const getCrimeDataPerPS = () => {
            for (const crime of allCrimeData) {
              if (crime.PS === " ALIPUR ") {
                CrimeDataPerPS[0].no_of_crime += 1;
              }
              if (crime.PS === " BAWANA ") {
                CrimeDataPerPS[1].no_of_crime += 1;
              }
              if (crime.PS === " BHALSWA DAIRY ") {
                CrimeDataPerPS[2].no_of_crime += 1;
              }
              if (crime.PS === " NARELA ") {
                CrimeDataPerPS[3].no_of_crime += 1;
              }
              if (crime.PS === "  NARELA  INDUSTRIAL AREA ") {
                CrimeDataPerPS[4].no_of_crime += 1;
              }
              if (crime.PS === "SP Badli") {
                CrimeDataPerPS[5].no_of_crime += 1;
              }
              if (crime.PS === " SHAHBAD DAIRY ") {
                CrimeDataPerPS[6].no_of_crime += 1;
              }
              if (crime.PS === "Swaroop Nagar") {
                CrimeDataPerPS[7].no_of_crime += 1;
              }
            }
        };
        getCrimeDataPerPS();

    },[]);  

    return(
        <Bar data={psCrimeData} options={options}/>
    )
}

export default CrimechartperPS;

  