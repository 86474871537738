import React, { useState, useEffect } from 'react';
import Select from 'react-select'; // Import react-select
import axios from 'axios';
import OuterNorthDistrictMap from '../../maps_google/outerNorthDistrictMap';
import Header from '../../Header/header';
import Sidebar from '../../maps_google/sidebar/sidebar';
import ANPR from './ANPR.json';
import GOVT from './GOVT.json';
import ECIL from './ECIL.json';
import { API_URL } from '../../config/config';

const styles = {
  container: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ddd',
    padding: '10px 20px',
    borderRadius: '8px',
    marginTop: "70px",
    gap: '15px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 10,
  },
  label: {
    backgroundColor: '#87CEEB',
    color: '#333',
    padding: '8px 12px',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  entriesBox: {
    marginTop: '10px',
    padding: '10px 15px',
    backgroundColor: '#87CEEB',
    color: '#333',
    fontWeight: 'bold',
    borderRadius: '8px',
    fontSize: '14px',
    textAlign: 'center',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
  },
  mapContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
};

// Camera type options for multi-select
const cameraTypeOptions = [
  { value: "ANPR", label: "ANPR" },
  { value: "govt", label: "Government" },
  { value: "ECIL", label: "ECIL" },
  { value: "private", label: "Private" },
];

// Custom styles for react-select with black placeholder text
const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    width: '200px',
    padding: '5px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    marginBottom: '10px',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'black',
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
};



const CctvTracking = () => {
  const [selectedCameraTypes, setSelectedCameraTypes] = useState([]);
  const [selectedPS, setSelectedPS] = useState([]);
  const [cctvData, setCctvData] = useState([]);
  const [totalEntries, setTotalEntries] = useState(0);
  const [psOptions, setPsOptions] = useState([]);

  const sheetId = '1QZkX__3SnwEHk6vZnOzuILHCXkkqyLNJ0ZK0Y4OJL8s';
  const apiKey = 'AIzaSyCu9pysN7NS0U7NdvFkxH1TDonMfn5Ne0U';
  const range = 'Sheet1!A1:G1000';
  const apiUrl = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${range}?key=${apiKey}`;

  useEffect(() => {
    fetchPrivateCameraData().then(privateData => {
      const uniquePS = Array.from(new Set(privateData.map(data => data.policeStation)))
        .map(ps => ({ value: ps, label: ps }));
      setPsOptions(uniquePS);
    });
  }, []);

  const fetchPrivateCameraData = async () => {
    try {
      const response = await axios.get(apiUrl);
      if (!response.data || !response.data.values) {
        console.error('No data available from Google Sheets');
        return [];
      }
      const rows = response.data.values;

      return rows.slice(1).map(row => ({
        name: row[0] || 'Unknown', // Provide default value if missing
        phone: row[1] || 'No phone provided',
        lat: parseFloat(row[2]) || 0, // Default to 0 if not parseable
        long: parseFloat(row[3]) || 0, // Default to 0 if not parseable
        cameraType: (row[4] && row[4].toLowerCase()) || 'not specified', // Handle undefined or null
        notes: row[5] || 'No notes',
        policeStation: row[6] || 'Not assigned'
      }));
    } catch (error) {
      console.error('Error fetching data from Google Sheets:', error);
      return [];
    }
  };

  // Fetching Camera Data for ECIL, govt, ANPR
  const fetchCameraData = async (type) => {
    try {
      const response = await fetch(`${API_URL}/api/cctv/type/${type}`);
      const data = await response.json();
      // console.log(data);
      if (!data) {
        console.error('No data available from API');
        return [];
      }
      else{
        return data;
      } 
    } catch (error) {
      console.error('Error fetching data from API:', error);
      return [];
    }
  }
  

  const handlePSChange = (selectedOptions) => {
    setSelectedPS(selectedOptions);
    filterCctvData(selectedCameraTypes, selectedOptions);
  };

  const handleCameraTypeChange = (selectedOptions) => {
    setSelectedCameraTypes(selectedOptions);
    filterCctvData(selectedOptions, selectedPS);
  };

  const filterCctvData = async (cameraTypes, policeStations) => {
    const typeFilter = cameraTypes.map(option => option.value);
    const psFilter = policeStations.map(option => option.value);

    let filteredData = [];

    // add filtered data of private cameras
    if (typeFilter.includes('private')) {
      const privateData = await fetchPrivateCameraData();
      filteredData = privateData.filter(item =>
        (typeFilter.length === 0 || typeFilter.includes(item.cameraType)) &&
        (psFilter.length === 0 || psFilter.includes(item.policeStation))
      );
    }

    // add filtered data of ECIL, ANPR cameras
    const cameras = ['ECIL', 'ANPR', 'govt'];
    for (const camera of cameras) {
      if (typeFilter.includes(camera)) {
        const cameraData = await fetchCameraData(camera) || [];
        if (cameraData) { 
          filteredData =[...filteredData, ...cameraData.filter(item =>
            (typeFilter.length === 0 || typeFilter.includes(item.camera_type)) &&
            (psFilter.length === 0 || psFilter.includes(item.PS))
          )];
        }
      }
    }

    // Add data from JSON files
    // if (typeFilter.includes('ANPR')) filteredData = [...filteredData, ...ANPR.filter(item => psFilter.length === 0 || psFilter.includes(item.PS))];
    // if (typeFilter.includes('govt')) filteredData = [...filteredData, ...GOVT.filter(item => psFilter.length === 0 || psFilter.includes(item.PS))];
    // if (typeFilter.includes('ECIL')) filteredData = [...filteredData, ...ECIL.filter(item => psFilter.length === 0 || psFilter.includes(item.PS))];

    setCctvData(filteredData);
    setTotalEntries(filteredData.length);
  };

  return (
    <>
      <Header />
      <Sidebar />

      <div style={styles.container}>
        <div style={styles.label}>CCTV TRACKING</div>

        <div style={styles.selectContainer}>
          <Select
            options={cameraTypeOptions}
            isMulti
            value={selectedCameraTypes}
            onChange={handleCameraTypeChange}
            placeholder="Select Camera Type(s)"
            styles={customSelectStyles}
          />
          <Select
            options={psOptions}
            isMulti
            value={selectedPS}
            onChange={handlePSChange}
            placeholder="Select Police Station(s) [AFTER SELECING CAMERA]"
            styles={customSelectStyles}
          />
        </div>

        <div style={styles.entriesBox}>
          Total Entries: <strong>{totalEntries}</strong>
        </div>
      </div>

      <div style={styles.mapContainer}>
        <OuterNorthDistrictMap cctv={cctvData} />
      </div>
    </>
  );
};

export default CctvTracking;
