import { createSlice } from '@reduxjs/toolkit';

const polygonSlice = createSlice({
    name: 'polygon',
    initialState: {
        selectedPolygon: null, 
        selectedPS: null,
        PS: null, 
        Beat: null,      
        Crime: null,      
    },
    reducers: {
        setSelectedPolygon: (state, action) => {
            state.selectedPolygon = action.payload;
        },
        clearSelectedPolygon: (state) => {
            state.selectedPolygon = null;
        },
        setSelectedPS: (state, action) => {
            state.selectedPS = action.payload; 
        },
        clearSelectedPS: (state) => {
            state.selectedPS = null; 
        },
        setPS: (state, action) => {
            state.PS = action.payload; 
        },
        clearPS: (state) => {
            state.PS = null;
        },
        setBeat: (state, action) => {
            state.Beat = action.payload; 
        },
        clearBeat: (state) => {
            state.Beat = null;
        },
        setCrime: (state, action) => {
            state.Crime = action.payload;
        },
        clearCrime: (state) => {
            state.Crime = null;
        },
    },
});

export const { setSelectedPolygon, clearSelectedPolygon, setSelectedPS, clearSelectedPS, setPS, clearPS, setBeat, clearBeat, setCrime, clearCrime } = polygonSlice.actions;

export default polygonSlice.reducer;
