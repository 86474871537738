import { configureStore } from '@reduxjs/toolkit';
import polygonReducer from './state/polygonSlice';

const store = configureStore({
    reducer: {
        polygon: polygonReducer,
    },
});

export default store;
