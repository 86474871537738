import samaypurBadliBeat10 from './beat10.json'
import samaypurBadliBeat09 from './beat09.json'
import samaypurBadliBeat08 from './beat08.json'
import samaypurBadliBeat07 from './beat07.json'
import samaypurBadliBeat05 from './beat05.json'
import samaypurBadliBeat06 from './beat06.json'
import samaypurBadliBeat04 from './beat04.json'
import samaypurBadliBeat01 from './beat01.json'

import crimeDataMay from '../../Tracking_feature/crime_tracking_FIR/crime_data_may.json';

const SamaypurBadliBeat10 = samaypurBadliBeat10;
const SamaypurBadliBeat09 = samaypurBadliBeat09;
const SamaypurBadliBeat08 = samaypurBadliBeat08;
const SamaypurBadliBeat07 = samaypurBadliBeat07;
const SamaypurBadliBeat06 = samaypurBadliBeat06;
const SamaypurBadliBeat05 = samaypurBadliBeat05;
const SamaypurBadliBeat04 = samaypurBadliBeat04;
const SamaypurBadliBeat01 = samaypurBadliBeat01;


const samaypurBaldiConfigs = [
    { paths: SamaypurBadliBeat10, crime: 0, name: 'Beat10', polygonId: 10, ps: "SP Badli"}, 
    { paths: SamaypurBadliBeat09, crime: 0, name: 'Beat09', polygonId: 9, ps: "SP Badli"}, 
    { paths: SamaypurBadliBeat08, crime: 0, name: 'Beat08', polygonId: 8, ps: "SP Badli"}, 
    { paths: SamaypurBadliBeat07, crime: 0, name: 'Beat07', polygonId: 7, ps: "SP Badli"}, 
    { paths: SamaypurBadliBeat06, crime: 0, name: 'Beat06', polygonId: 6, ps: "SP Badli"}, 
    { paths: SamaypurBadliBeat05, crime: 0, name: 'Beat05', polygonId: 5, ps: "SP Badli"},
    { paths: SamaypurBadliBeat04, crime: 0, name: 'Beat04', polygonId: 4, ps: "SP Badli"},
    { paths: SamaypurBadliBeat01, crime: 0, name: 'Beat01', polygonId: 1, ps: "SP Badli"},
];

const allCrimeData = [ ...crimeDataMay];

for (const crime of allCrimeData) {
    if (crime.PS === "SP Badli") {
        if (crime.BEAT == 10) {
            samaypurBaldiConfigs[0].crime += 1;
        }
        if (crime.BEAT == 9) {
            samaypurBaldiConfigs[1].crime += 1;
        }
        if (crime.BEAT == 8) {
            samaypurBaldiConfigs[2].crime += 1;
        }
        if (crime.BEAT == 7) {
            samaypurBaldiConfigs[3].crime += 1;
        }
        if (crime.BEAT == 6) {
            samaypurBaldiConfigs[4].crime += 1;
        }
        if (crime.BEAT == 5) {
            samaypurBaldiConfigs[5].crime += 1;
        }
        if (crime.BEAT == 4) {
            samaypurBaldiConfigs[6].crime += 1;
        }
        if (crime.BEAT == 1) {
            samaypurBaldiConfigs[7].crime += 1;
        }
    }
}

function calculateColor(crimes, maxCrimes) {
    const intensity = crimes / maxCrimes; 
    return `rgba(173, 255, 47, ${intensity})`
}

const maxCrimes = Math.max(...samaypurBaldiConfigs.map(config => config.crime));


const updatedConfigs = samaypurBaldiConfigs.map(config => {
    const color = calculateColor(config.crime, maxCrimes);
    return { ...config, color }; 
});



export default updatedConfigs;