import niaBeat01 from './beat01.json'
import niaBeat02 from './beat02.json'
import niaBeat03 from './beat03.json'
import niaBeat04 from './beat04.json'
import niaBeat05 from './beat05.json'
import niaBeat06 from './beat06.json'

import crimeDataMay from '../../Tracking_feature/crime_tracking_FIR/crime_data_may.json';

const NIABeat01 = niaBeat01;
const NIABeat02 = niaBeat02;
const NIABeat03 = niaBeat03;
const NIABeat04 = niaBeat04;
const NIABeat05 = niaBeat05;
const NIABeat06 = niaBeat06;


const niaConfigs = [
    { paths: NIABeat01, crime: 0, name: 'Beat01', polygonId: 1, ps: "  NARELA  INDUSTRIAL AREA "}, 
    { paths: NIABeat02, crime: 0, name: 'Beat02', polygonId: 2, ps: "  NARELA  INDUSTRIAL AREA "}, 
    { paths: NIABeat03, crime: 0, name: 'Beat03', polygonId: 3, ps: "  NARELA  INDUSTRIAL AREA "}, 
    { paths: NIABeat04, crime: 0, name: 'Beat04', polygonId: 4, ps: "  NARELA  INDUSTRIAL AREA "}, 
    { paths: NIABeat05, crime: 0, name: 'Beat05', polygonId: 5, ps: "  NARELA  INDUSTRIAL AREA "}, 
    { paths: NIABeat06, crime: 0, name: 'Beat06', polygonId: 6, ps: "  NARELA  INDUSTRIAL AREA "}, 
  ]

const allCrimeData = [ ...crimeDataMay];

for (const crime of allCrimeData) {
    if (crime.PS === "  NARELA  INDUSTRIAL AREA ") {
        if (crime.BEAT == 1) {
            niaConfigs[0].crime += 1;
        }
        if (crime.BEAT == 2) {
            niaConfigs[1].crime += 1;
        }
        if (crime.BEAT == 3) {
            niaConfigs[2].crime += 1;
        }
        if (crime.BEAT == 4) {
            niaConfigs[3].crime += 1;
        }
        if (crime.BEAT == 5) {
            niaConfigs[4].crime += 1;
        }
        if (crime.BEAT == 6) {
            niaConfigs[5].crime += 1;
        }
    }
}

function calculateColor(crimes, maxCrimes) {
    const intensity = crimes / maxCrimes; 
    return `rgba(0, 0, 255, ${intensity})`
}

const maxCrimes = Math.max(...niaConfigs.map(config => config.crime));


const updatedConfigs = niaConfigs.map(config => {
    const color = calculateColor(config.crime, maxCrimes);
    return { ...config, color }; 
});



export default updatedConfigs;