import React from 'react';
import OuterNorthDistrictMap from '../../maps_google/outerNorthDistrictMap';
import Header from '../../Header/header';
import Sidebar from '../../maps_google/sidebar/sidebar';
import { useNavigate } from 'react-router-dom';

function Vehicle_tracking() {
  const navigate= useNavigate()
  return (
    <>

   <Header/>
   <Sidebar/>
   < OuterNorthDistrictMap/>
    </>
  );
}

export default Vehicle_tracking;
